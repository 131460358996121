<h1 *ngIf="data.title" mat-dialog-title>
    {{ data.title }}
</h1>

<mat-dialog-content [innerHTML]="data.message"></mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="!data.reject" mat-button [mat-dialog-close]="true" cdkFocusInitial>
        {{ data.accept }}
    </button>
    <button *ngIf="data.reject" mat-button [mat-dialog-close]="true">
        {{ data.accept }}
    </button>
    <button *ngIf="data.reject" mat-button [mat-dialog-close]="false" cdkFocusInitial>
        {{ data.reject }}
    </button>
</mat-dialog-actions>
