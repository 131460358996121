<table mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngIf="selectable" matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="select">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef>{{ 'toolbox-admin.toolImage' | translate }}</th>
        <td mat-cell *matCellDef="let tool" class="image-container">
            <img src="{{ tool.imageUrl }}" />
        </td>
    </ng-container>
    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="title-container" mat-sort-header>{{ 'toolbox-admin.toolTitle' | translate }}</th>
        <td mat-cell *matCellDef="let tool" class="title-container">
            {{ tool.title }}
        </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef class="timelapse-container"><mat-icon>timelapse</mat-icon></th>
        <td mat-cell *matCellDef="let tool" class="timelapse-container">
           {{ tool.durationInMinutes }} min
        </td>
    </ng-container>

    <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef class="author-container">{{ 'toolbox-admin.toolAuthor' | translate }}</th>
        <td mat-cell *matCellDef="let tool" class="author-container">
           {{ tool.author }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let tool" class="read-container">

            <a (click)="onItemSuppressed(tool)"><mat-icon>delete</mat-icon> Remove</a>
            <a routerLink="{{ toolViewUrl(tool)}}" target="_blank">
                <mat-icon>open_in_new</mat-icon>
            </a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
