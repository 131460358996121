import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function OrganisationsRoutes(organisationsComponent?: Type<any>, organisationComponent?: Type<any>): Route[] {
    return [
        {
            path: '',
            data: { i18n: 'organisations' },
            children: [
                { path: '',    component: organisationsComponent, data: { page: Page.Organisations } },
                { path: ':id', component: organisationComponent,  data: { page: Page.Organisation } }
            ]
        }
    ];
}
