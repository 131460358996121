<div class="content-container">
    <div class="text-container" [@simpleFadeAnimation]="'in'">
        <p class="text justify">{{welcome}}</p>
        <hr />
        <div class="button-wrapper">
            <button class="primary start" (click)="start()">{{localization.getString('quiz.start')}}</button>
        </div>
      
    </div>
</div>
