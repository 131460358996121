import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService, AppModel, NavigationService } from '@app/core';
import { Page } from '@app/global';

@Injectable()
export class OnBoardingService {

    constructor(private http: HttpClient,
        private app: AppService,
        private appModel: AppModel,
        private navigationService: NavigationService) { }

    public isCompleted(challengeId: string): Promise<boolean> {
        return this.http.get<boolean>(`${this.app.baseUrl}api/onboarding/isCompleted`, {
            params: {
                challengeId
            }
        }).toPromise();
    }

    public async navigateToOnboarding(challengeId: string): Promise<void> {
        const isParticipant = await this.http.get<boolean>(`${this.app.baseUrl}api/participants/IsParticipantOfChallenge`, { params: { challengeId } })
            .toPromise();

        if ((!this.appModel.isAdminOrOrganizer() || (this.appModel.isOrganizer() && isParticipant)) && !await this.isCompleted(challengeId)) {
            this.navigationService.navigateToPage(Page.OnBoardingLogin, challengeId);
            return Promise.reject();
        }

        return Promise.resolve();
    }
}
