import { Injectable     } from '@angular/core';
import { Location       } from '@angular/common'
import { Router, Params } from '@angular/router';

@Injectable()
export class BrowserService
{
    constructor(private router:   Router,
                private location: Location) { }

    public clearQueryParams()
    {
        this.location.replaceState(this.getUrlPath());
    }

    public setQueryParams(params: Params)
    {
        let tree = this.router.parseUrl(this.location.path(true));

        tree.queryParams = params;

        this.location.replaceState(this.router.serializeUrl(tree));
    }

    public setQueryParam(key: string, value: any)
    {
        let tree = this.router.parseUrl(this.location.path(true));

        if (value)
            tree.queryParams[key] = value;
        else
            delete tree.queryParams[key];

        this.location.replaceState(this.router.serializeUrl(tree));
    }

    private getUrlPath(): string
    {
        return this.location.path().split('?')[0];
    }
}
