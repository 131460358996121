<div *ngIf="!started">
    <enl-quiz-welcome [welcome]="quiz.welcome"
                      [localization]="localization"
                      (onStart)="start()"></enl-quiz-welcome>
</div>
<div *ngIf="started" class="content-container">

    <div class="question-container" [@simpleFadeAnimation]="transit ? 'in' : 'out'">
        <h2>Question {{answeredQuestion.index}}</h2>
        <p *ngIf="localization.exists('quiz.select-answer')" class="select-answer">{{localization.getString('quiz.select-answer')}}</p>

        <h3>{{answeredQuestion.question.label}}</h3>

        <mat-radio-group [value]="answeredQuestion.answer" class="answers">
            <mat-radio-button *ngFor="let a of quiz.answers" [value]="a" class="radiobutton-label answer-button" (click)="onChange(a)">
                <span [ngClass]="{'label-selected': answeredQuestion.answer === a}">{{a.label}}</span>
                <div class="answer" [ngClass]="{'selected': answeredQuestion.answer === a}"></div>
            </mat-radio-button>
        </mat-radio-group>

        <div class="button-container">
            <button class="secondary" (click)="previous()" [disabled]="!answeredQuestion.previous"><mat-icon>arrow_back_ios</mat-icon> {{ localization.getString('quiz.previous')}}</button>
            <button class="primary" (click)="next()" [disabled]="!answeredQuestion.next ||!answeredQuestion.answer">{{localization.getString('quiz.next')}} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>

        <hr *ngIf="completed()" />

        <button *ngIf="completed()" class="primary finish" (click)="complete()">{{localization.getString('quiz.complete')}}</button>

    </div>


</div>

