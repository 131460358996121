import { Validators as FormsValidators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

function isEmpty(value: any): boolean
{
    return value == null || value.length === 0;
}

export interface CustomError     { (): string; }
export interface CustomValidator { (): CustomError | null; }

export class Validators extends FormsValidators
{
    static custom(validator: CustomValidator): ValidatorFn
    {
        return (control: AbstractControl): ValidationErrors | null =>
        {
            // (allow optional controls)
            if (isEmpty(control.value))
                return null;

            let result = validator();
            return result ? { 'custom': result } : null;
        };
    }

    static customNotOptional(validator: CustomValidator): ValidatorFn
    {
        return (control: AbstractControl): ValidationErrors | null =>
        {
        
            let result = validator();
            return result ? { 'custom': result } : null;
        };
    }

    static password(control: AbstractControl): ValidationErrors | null
    {
        // (allow optional controls)
        if (isEmpty(control.value))
            return null;

        // At least 8 characters, with one uppercase, one lowercase, one digit
        const regexp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

        return regexp.test(control.value) ? null : { 'password': true };
    }

    static phone(control: AbstractControl): ValidationErrors | null
    {
        // (allow optional controls)
        if (isEmpty(control.value))
            return null;

        // North American pattern based on https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
        const regexp: RegExp = /^(?:\+?1[- ]?)?\(?([2-9][0-8][0-9])\)?[- ]?([2-9][0-9]{2})[- ]?([0-9]{4})$/;

        return regexp.test(control.value) ? null : { 'phone': true };
    }
}
