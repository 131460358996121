import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/shared';

import { FormsModule } from '@angular/forms';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackService } from './services/feedback.service';
import { FeedbackToUserComponent } from "@app/modules/feedback/components/feedback-to-user/feedback-to-user.component"
import { DashboardService } from '../dashboard/services/dashboard.service';
import { AutosizeModule } from 'ngx-autosize';

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        AutosizeModule
    ],
    declarations: [
        FeedbackFormComponent,
        FeedbackComponent,
        FeedbackToUserComponent,
    ],
    providers: [FeedbackService, DashboardService],
   exports: [
      FeedbackFormComponent,
       FeedbackComponent,
      FeedbackToUserComponent
]
})

export class FeedbackModule { }
