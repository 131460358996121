import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppModel } from '@app/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@app/core';
import { RouteTools } from '@app/tools';
import { IPlan } from "../../models/plan";
import { ToolBoxService } from "../../services/toolbox.service";
import { LocalizationService } from '@app/core';
import { NotificationService } from '@app/core';
import { FormService } from '@app/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'send-content',
    templateUrl: './send-content.component.html',
    styleUrls: ['./send-content.component.css']
})
export class SendContentComponent implements OnInit {
    public readonly form: FormGroup;

    public readonly url = new FormControl('', [Validators.required, Validators.pattern('https?://.*')]);
    public readonly theme = new FormControl('', [Validators.required]);
    public isBusy: boolean = false;
    public plan: IPlan;
    private readonly planId: string;
    public loaded: boolean = false;

    constructor(route: ActivatedRoute,
        public app: AppModel,
        private toolbox: ToolBoxService,
        private localizationService: LocalizationService,
        private notificationService: NotificationService,
        public formService: FormService,
        private titleService: Title) {
        this.planId = RouteTools.getQueryParams(route)['planId'];
        this.form = new FormGroup({ url: this.url, theme: this.theme });
    }

    public async ngOnInit() {
        this.plan = await this.toolbox.getPlan(this.planId, this.app.language);
        this.titleService.setTitle(this.plan.name);
        this.loaded = true;
    }

    public async onSubmit() {
        try {
            this.isBusy = true;

            await this.toolbox.sendContent
                (
                    this.planId,
                    this.theme.value,
                    this.url.value
                );

            this.form.reset();
            this.notificationService.showMessage(this.localizationService.getString("send-tool.sent"));
        }
        finally {
            this.isBusy = false;
        }
    }
}
