import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppModel, TranslateService } from '@app/core';
import { ITool } from '../../models/tool';
import { ToolBoxService } from '../../services/toolbox.service';
import { DocumentAddComponent } from '../../components/document-add/document-add.component';
import { IToolBox } from '../../models/toolbox';
import { QuizAddComponent } from '../../components/quiz-add/quiz-add.component';
import { DocumentService } from '../../services/document.service';
import { QuizService } from '../../services/quiz.service';
import { IQuiz } from '../../models/quiz';
import { IDocument } from '../../models/document';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationService } from "@app/core/services/notification.service";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'toolbox-admin',
    templateUrl: './toolbox-admin.component.html',
    styleUrls: ['../../../shared/admin.shared.css', './toolbox-admin.component.css']
})
export class ToolboxAdminComponent implements OnInit {
    public toolboxes: IToolBox[] = [];
    public documents: IDocument[] = [];
    public quizzes: IQuiz[] = [];

    selectedDocuments: SelectionModel<ITool> = new SelectionModel<ITool>(true, []);
    selectedQuizzes: SelectionModel<ITool> = new SelectionModel<ITool>(true, []);

    constructor(public app: AppModel,
        private toolboxService: ToolBoxService,
        private documentService: DocumentService,
        private quizService: QuizService,
        public dialog: MatDialog,
        private notificationService: NotificationService,
        translateService: TranslateService,
        titleService: Title) {
        titleService.setTitle(translateService.instant('toolbox-admin.headers.tools'));
    }

    public async ngOnInit() {
        this.toolboxes = await this.toolboxService.getAllToolBoxes();
        this.documents = await this.documentService.getAll();
        this.quizzes = await this.quizService.getAll();
    }

    openAddDocumentDialog() {
        let dialogRef = this.dialog.open(DocumentAddComponent,
            {
                width: '80%',
                maxHeight: '75%',
                panelClass: 'modal-container',
            });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    openAddQuizDialog() {
        let dialogRef = this.dialog.open(QuizAddComponent,
            {
                width: '80%',
                maxHeight: '75%',
                panelClass: 'modal-container',
            });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }

        });
    }

    addSelectedTools(toolboxId: string) {
        let tools: ITool[] = this.selectedQuizzes.selected.concat(this.selectedDocuments.selected);

        if (tools.length > 0) {
            this.toolboxService.addTools(toolboxId, tools)
                .then(() => {
                    this.selectedDocuments.clear();
                    this.selectedQuizzes.clear();
                    this.ngOnInit();
                    this.notificationService.showMessage("Done");
                });
        }
    }

    public deleteQuiz(quiz: ITool) {
        this.quizService.delete(quiz.id).then(() => this.ngOnInit());
    }

    public deleteDocument(document: ITool) {
        this.documentService.delete(document.id).then(() => this.ngOnInit());
    }

    public removeFromToolBox(tool: ITool, toolboxId: string) {
        this.toolboxService.remove(tool, toolboxId).then(() => this.ngOnInit());
    }
}
