import { Injectable, Injector                                                         } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable, empty, throwError                                                              } from 'rxjs';
import { AppModel                                                                     } from '../models/app.model';
import { AppService,                                                                  } from '../services/app.service';
import { DialogService                                                                } from '../services/dialog.service';
import { NavigationService                                                            } from '../services/navigation.service';
import { LocalizationService                                                          } from '../services/localization.service';
import { NotificationService                                                          } from '../services/notification.service';
import { catchError } from "rxjs/operators";
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor
{
    private readonly ssr_cookie: string;

    constructor(private appModel:       AppModel,
                private appService:     AppService,
                private dialog:         DialogService,
                private navigation:     NavigationService,
                private localization:   LocalizationService,
                private notification:   NotificationService,
                private tokenExtractor: HttpXsrfTokenExtractor,
                        injector:       Injector)
    {
        if (this.appService.isPlatformServer)
            this.ssr_cookie = injector.get('SSR_COOKIE');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const HTTP_ERROR_STATUS_BASE   = 400;
        const HTTP_UNAUTHORIZED_STATUS = 401;

        // Transmit XSRF token on changing-state requests
        // (Needed when absolute URLs are used, because Angular send XSRF header only on relative URLs by default)
        if (this.isTokenRequired(request))
        {
            let token = this.tokenExtractor.getToken() as string;
            if (token !== null)
                request = request.clone({ setHeaders: { 'X-XSRF-TOKEN': token }});
        }

        // Add ViewId header (empty value on server-side)
        if (this.appService.isPlatformBrowser)
            request = request.clone({ setHeaders: { 'X-VIEWID': this.appService.viewId }});
        else
            request = request.clone({ setHeaders: { 'X-VIEWID': '00000000-0000-0000-0000-000000000000' }});

        // Inject cookies in request, if calling itself (server-side only)
        if (this.ssr_cookie && this.appService.isPlatformServer && request.url.startsWith(this.appService.baseUrl))
            request = request.clone({ setHeaders: { 'cookie': this.ssr_cookie } });

        // Send request with error catching
        return next.handle(request).pipe(
            catchError((error, caught) =>
            {
                // Clear current identity and navigate to login page on unauthorized access
                if (error.status === HTTP_UNAUTHORIZED_STATUS)
                {
                    this.appModel.identity = null;
                    this.navigation.navigateToLogin();
                    return empty();
                }

                // Show error notification on any other error
                if (error.status >= HTTP_ERROR_STATUS_BASE)
                    this.notification.showError(this.localization.getString('global.errors.generic'),
                                                this.localization.getString('global.help'),
                                                () => {
                                                    this.dialog.show(this.localization.getString('global.errors.genericHelp', { requestId: error.headers.get('X-REQUESTID') }),
                                                                     this.localization.getString('global.help'));
                                                });

                // Rethrow error
                return throwError(error);
            })) as any;
    }

    private isTokenRequired(request: HttpRequest<any>): boolean
    {
        switch (request.method.toUpperCase())
        {
            case 'POST':
            case 'PUT':
            case 'DELETE':
            case 'PATCH':
                return true;
        }
        return false;
    }
}
