import { NgModule } from '@angular/core';
import { QuickFeedbackComponent } from './components/quick-feedback/quick-feedback.component';
import { FeedbackModule } from '../feedback';

@NgModule({
    imports: [
        FeedbackModule
    ],
    declarations: [
        QuickFeedbackComponent
    ],
    providers: [
        //FeedbackService
    ],
    exports: [
        QuickFeedbackComponent
    ]
})

export class QuickFeedbackModule { }
