import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AppModel } from '@app/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { INavigationNode } from '../../models/navigationNode';
import { RoutingService } from '@app/core';
import { Page } from '../../../../global';
import { ChallengeNavService } from '../../services/challenge-nav.service'
import { NodeType } from '../../models/nodeType';
import { RouteTools } from '../../../../tools';
import { ActivatedRoute } from '@angular/router';
import { AppService } from "@app/core/services/app.service";
import { NavigationMobileService } from "@app/modules/navigation/services/navigation.mobile.service";

@Component({
    selector: 'challenge-nav',
    templateUrl: './challenge-nav.component.html',
    styleUrls: ['./challenge-nav.component.css', './challenge-nav.component.mobile.css'],
})

export class ChallengeNavComponent implements OnInit {

    @Input() currentChallengeId: string;
    @Output() closeNav = new EventEmitter();
   
   treeControl = new NestedTreeControl<INavigationNode>(node => node.children);
   dataSource = new MatTreeNestedDataSource<INavigationNode>();

    constructor(public app: AppModel,
       private routingService: RoutingService,
        private challengeService: ChallengeNavService,
        private route: ActivatedRoute,
       private appService: AppService,
       private navigationService: NavigationMobileService) {
   }

   async ngOnInit() {
      let nodes = await this.challengeService.getUserOrganizationChallenges();

       this.dataSource.data = this.filterHiearchy(nodes);
       
   }

   hasChild = (_: number, node: INavigationNode) => !!node.children && node.children.length > 0;

   isNodeTeam = (_: number, node: INavigationNode) => node.type === NodeType.Team;
   isNodeChallenge = (_: number, node: INavigationNode) => node.type === NodeType.Challenge;
   isNodePlan = (_: number, node: INavigationNode) => node.type === NodeType.Plan;
   isNodeOrganization = (_: number, node: INavigationNode) => node.type === NodeType.Organization;
    get dashboardUrl(): string {
        return this.routingService.getPath(Page.Dashboard, this.app.language);
    }

    get FAQUrl(): string {
        return this.routingService.getPath(Page.Faq, this.app.language);
    }

    close() {
       this.closeNav.emit();
       this.navigationService.toogleSideNavigation.next();
    }

    filterHiearchy(nodes: INavigationNode[]) {

       if (nodes.length > 1)
         return nodes;

      if (nodes[0].type === 'team')
           return nodes;

       return this.filterHiearchy(nodes[0].children);
   }
}
