import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IQuiz } from '../models/quiz';
import { IUploadResult } from '../models/upload.result';
import { AppService, AppModel } from '@app/core';

@Injectable()
export class QuizService {
    constructor(private http: HttpClient,
        private appModel: AppModel,
        private app: AppService) {
    }


    public async getQuiz(quizId: string): Promise<IQuiz> {
        return this.http.get<IQuiz>(`${this.app.baseUrl}api/quiz/get`,
            {
                params: {
                    language: this.appModel.language,
                    quizId
                }
            }).toPromise();
    }

    public async getAll(): Promise<IQuiz[]> {
        return this.http.get<IQuiz[]>(`${this.app.baseUrl}api/quiz/getAll`,
            {
                params: {
                    language: this.appModel.language
                }
            }).toPromise();
    }

    public uploadQuiz(file: File, img: File, author: string, duration: number): Promise<IUploadResult> {

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('img', img, img.name);
        formData.append('author', author.trim());
        formData.append('duration', duration.toString());

        return this.http.post<IUploadResult>(`${this.app.baseUrl}api/quiz/Upload`, formData).toPromise();
    }

    public delete(quizId: string): Promise<void> {
        return this.http
            .post<void>(`${this.app.baseUrl}api/quiz/delete`, null, {
                params: {
                    quizId
                }
            }
        ).toPromise();
    }
}
