import { Component, OnInit, Input } from '@angular/core';
import { ITool } from '../../models/tool';
import { RoutingService } from '@app/core';
import { Page } from '@app/global';
import { AppModel } from '@app/core';
import { ToolType } from '../../models/tool.type';


@Component({
  selector: 'app-tool-card',
  templateUrl: './tool-card.component.html',
  styleUrls: ['./tool-card.component.css']
})
export class ToolCardComponent implements OnInit {
    @Input() tool: ITool;
    public types = ToolType;
    constructor(public app: AppModel,
        private routing:  RoutingService) { }

  ngOnInit() {
  }

    get toolUrl() : string{
        switch (this.tool.type) {
            case ToolType.Document:
                return this.routing.getPath(Page.Document, this.app.language, this.tool.id);
            case ToolType.Quiz:
               return this.routing.getPath(Page.Quiz, this.app.language, this.tool.id);
            default:
                throw new Error('Unknown tool type.');
        }
    }

    get imageBackground() {
        return `url(${this.tool.imageUrl})`;
    }
}
