import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot } from '@angular/router';
import { Language } from '@app/global';
import { RouteSnapshotTools } from '@app/tools';
import { AppModel } from '../models/app.model';
import { RoutingService } from '../services/routing.service';
import { TranslateService } from '../services/translate.service';

@Injectable()
export class LanguageGuard implements CanActivateChild {
    constructor(private app: AppModel,
        private router: Router,
        private routing: RoutingService,
        private translate: TranslateService) { }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            this.app.language = RouteSnapshotTools.getLanguage(childRoute);

            if (!this.app.language)
                await this.router.navigateByUrl(this.routing.getPathToLanguage(Language.Default), { replaceUrl: true });
            else
                await this.translate.load(this.app.language,
                    childRoute.data['i18n']);
        }
        catch (ex) {
            console.error(ex);
        }
        return true;
    }
}
