import { Component         } from '@angular/core';
import { NavigationService } from '@app/core';
import { Language          } from '@app/global';
import { AppModel          } from '@app/core';

@Component
({
    selector:    'language-switch',
   templateUrl: './language-switch.component.html',
   styleUrls: ['./language-switch.component.css']
})
export class LanguageSwitchComponent {
    constructor(private app: AppModel,
        private navigation: NavigationService) { }

    public switchLanguage() {
        let lang = (this.app.language === Language.French ? Language.English : Language.French);

        this.navigation.navigateToLanguage(lang);
    }
}
