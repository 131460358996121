import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService, AppModel } from '@app/core';
import { ITeam }  from "../models/team";
import { IParticipant } from '../models/participant';
import { Language } from '@app/global';
import { IFeedback } from "../models/feedback";
import { IFeeling } from "../models/feeling";
import { IPeriodConfidence } from "../models/analytics/periodConfidence";
import { IChallengeParticipants } from "../models/challengeParticipants";
import { IChallenge} from '../models/challenge';
import { IChallenge as IChallengeDetail } from '../../shared/models/challenge';

@Injectable()
export class DashboardService
{
    constructor(private http: HttpClient,
        private app: AppService,
        private appModel: AppModel) {
    }

    public getChallenge(challengeId: string): Promise<IChallenge> {
        let language = this.appModel.language;
        return this.http.get<IChallenge>(`${this.app.baseUrl}api/dashboard/challenge`, {
            params: {
                challengeId,
                language
            }
        }).toPromise();
    }

    public getChallengeDetail(challengeId: string): Promise<IChallengeDetail> {
        let language = this.appModel.language;
        return this.http.get<IChallengeDetail>(`${this.app.baseUrl}api/management/challenge`, {
            params: {
                challengeId,
                language
            }
        }).toPromise();
    }

    public getTeam(): Promise<ITeam>
    {
        let language = this.appModel.language;
        return this.http.get<ITeam>(`${this.app.baseUrl}api/dashboard/Team/default`, {
            params: {
                language
            }
        }).toPromise();
    }

    public getTeamById(teamId: string): Promise<ITeam> {
        let language = this.appModel.language;
        return this.http.get<ITeam>(`${this.app.baseUrl}api/dashboard/Team`, {
            params: {
                teamId,
                language
            }
        }).toPromise();
    }

    public getTeamFromChallengeId(challengeId: string): Promise<ITeam> {
        let language = this.appModel.language;
        return this.http.get<ITeam>(`${this.app.baseUrl}api/dashboard/Team/Challenge`, {
            params: {
                challengeId,
                language
            }
        }).toPromise();
    }

    public getParticipants(challengeid: string, language: Language): Promise<IChallengeParticipants> {
        return this.http.get<IChallengeParticipants>(`${this.app.baseUrl}api/dashboard/Challenge/${challengeid}/Participants?language=${language}`).toPromise();
    }

    public getParticipantsOfTeam(teamId: string) : Promise<IParticipant[]> {
        return this.http.get<IParticipant[]>(`${this.app.baseUrl}api/dashboard/team/${teamId}/participants`).toPromise();
    }

    public updateStrengths(participantid: string, challengeid: string, commitmentId: string,strengths:string[]): Promise<string> {
        let commitmentInput = {
            participantid: participantid,
            challengeid: challengeid,
            Id: commitmentId,
            strengths: strengths
        }
        return this.http.post<string>(`${this.app.baseUrl}api/dashboard/Commitment/Strengths/Update`, commitmentInput).toPromise();
    }
    public async updateMentor(participantId: string, challengeId: string, commitmentId: string, mentor: string): Promise<string> {

        let commitmentInput = {
            participantid: participantId,
            challengeid: challengeId,
            Id: commitmentId,
            mentorUserId: mentor,
        }
        return this.http.post<string>(`${this.app.baseUrl}api/dashboard/Commitment/Mentor/Update`, commitmentInput).toPromise();
    }

    public async deleteMentor(commitmentId: string) {
        let commitmentIdInput =
        {
            commitmentId : commitmentId,
        }
        return this.http.post(`${this.app.baseUrl}api/dashboard/Commitment/Mentor/Delete`, commitmentIdInput).toPromise();
    }

    public updateDescription(participantid: string, challengeid: string, commitmentId: string, description:string): Promise<string> {
        let commitmentInput = {
            participantid: participantid,
            challengeid: challengeid,
            Id: commitmentId,
            description: description.trim(),
        }
        return this.http.post<string>(`${this.app.baseUrl}api/dashboard/Commitment/Description/Update`, commitmentInput).toPromise();
    }

    public broadCastDescription(participantid: string, challengeid: string, commitmentId: string, description: string): Promise<void> {
        let commitmentInput = {
            participantid: participantid,
            challengeid: challengeid,
            Id: commitmentId,
            description: description.trim(),
        }
        return this.http.post<void>(`${this.app.baseUrl}api/dashboard/Commitment/broadCast/description`, commitmentInput).toPromise();
    }

    public updateDerailleur(participantid: string, challengeid: string, commitmentId: string, derailleur: string): Promise<string> {
        let commitmentInput = {
            participantid: participantid,
            challengeid: challengeid,
            Id: commitmentId,
            derailleur: derailleur.trim(),
        }
        return this.http.post<string>(`${this.app.baseUrl}api/dashboard/Commitment/Derailleur/Update`, commitmentInput).toPromise();
    }

    public updateSuccess(commitmentId: string, success: boolean) {
        let successInput = {
            commitmentId: commitmentId,
            success: success,
        }
        return this.http.post<any>(`${this.app.baseUrl}api/dashboard/Commitment/UpdateSuccess`, successInput).toPromise();
    }


    public getSuggestedStrengths(language: Language): Promise<string[]> {
        return this.http.get<string[]>(`${this.app.baseUrl}api/strengths/suggested?language=${language}`).toPromise();
    }

    public addFeedback(challengeId: string, commitmentId: string, comment: string): Promise<IFeedback> {
        let feedbackInput = {
            CommitmentId: commitmentId,
            comment: comment.trim(),
            challengeId: challengeId
        };

        return this.http.post<IFeedback>(`${this.app.baseUrl}api/feedback/Add`, feedbackInput ).toPromise();
    }

    public updateFeedback(feedbackId: string, commitmentId: string, challengeId: string, comment: string): Promise<any> {
        let feedbackUpdateInput = { feedbackId: feedbackId, CommitmentId: commitmentId, challengeId: challengeId, comment: comment.trim() };
        return this.http.post<any>(`${this.app.baseUrl}api/feedback/Update`, feedbackUpdateInput).toPromise();
    }

    public deleteFeedback(feedback: string, commitmentId: string, challengeId : string):Promise<any> {
        let feedbackDeleteInput = { CommitmentId: commitmentId, FeedbackId: feedback, challengeId: challengeId };
        return this.http.post<any>(`${this.app.baseUrl}api/feedback/Delete`, feedbackDeleteInput).toPromise();
    }

    public getFeelings(language: Language): Promise<IFeeling[]> {
        return this.http.get<IFeeling[]>(`${this.app.baseUrl}api/feeling/list?language=${language}`).toPromise();
    }

    public getChallengeConfidenceLevels(challengeid: string): Promise<IPeriodConfidence[]> {
        return this.http.get<IPeriodConfidence[]>(`${this.app.baseUrl}api/analytic/Challenge/${challengeid}/confidence`).toPromise();
    }
}
