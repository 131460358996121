import { Injectable, Inject   } from '@angular/core';
import { DOCUMENT, Location   } from '@angular/common';
import { Router, Params       } from '@angular/router';
import { Page, Language, Role } from '@app/global';
import { RoutingService       } from './routing.service';
import { AppModel             } from '../models/app.model';

@Injectable()
export class NavigationService
{
    private default_url: string;

    constructor(private app:      AppModel,
                private router:   Router,
                private location: Location,
                private routing:  RoutingService,
                @Inject(DOCUMENT) private document: any) { }

    public navigate(page: Page, language: Language, id?: string, option?: string, queryParams?: Params) {

        this.router.navigate([this.routing.getPath(page, language, id, option)], { queryParams: queryParams });
    }

    public navigateFromLogin() {
     
        let url = this.default_url ? this.default_url : this.routing.getPathToPage(this.getDefaultPage());

        this.default_url = null;
        this.router.navigateByUrl(url, { replaceUrl: true });
    }

    public navigateToLogin()
    {
        this.default_url = this.location.path(true);
        this.router.navigateByUrl(this.routing.getPathToPage(Page.Login), { replaceUrl: true });
    }


    public navigateToLanguage(language: Language) {
        this.router.navigateByUrl(this.routing.getPathToLanguage(language)); }

    public navigateToDefaultPage() {
        this.router.navigateByUrl(this.routing.getPathToPage(this.getDefaultPage()));
    }

    public navigateToPage(page: Page,id?:string) {
        this.router.navigateByUrl(this.routing.getPathToPage(page,id)); }
    
    public navigateToUrl(url: string) {
        this.document.location.href = url; 
    }

    public getDefaultPage(): Page
    {
        if (this.isAdmin())
            return Page.DefaultAdmin;

        if (this.isOrganizer())
            return Page.DefaultOrganizer;

        if (this.isUser()) {
            return Page.Default;
        }
            

        return Page.Default;
    }

    private isOrganizer(): boolean {
        return this.app.identity && this.app.identity.roles.indexOf(Role.Organizer) >= 0; }

    private isAdmin(): boolean {
        return this.app.identity && this.app.identity.roles.indexOf(Role.Administrator) >= 0; }

    private isUser(): boolean {
        return this.app.identity && this.app.identity.roles.indexOf(Role.User) >= 0;
    }

    public addNewQueryParams(route, queryParams?: Params) {
        this.router.navigate([],
            {
                relativeTo: route,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
    }

    public get returnUrl(): string {
        return this.default_url;
    }
}


