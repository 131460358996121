<ng-container *ngIf="!isEditing; then readonly; else editing"></ng-container>

<ng-template #readonly>
    <div class="comment-container">
        <div class="comment-container-sender">
            <profile-picture [person]="feedback.person"></profile-picture>

            <div>
                <span><strong>{{feedback.person.firstName}} {{feedback.person.lastName}}</strong></span><br />
                <span class="date">{{feedback.createdDate | date: 'mediumDate'}}</span>
            </div>
        </div>
        <div *ngIf="feedback.canEdit" class="controls">
            <small (click)="setIsEditing()" class="comment-btn"><mat-icon>edit</mat-icon></small>
            <small (click)="delete()" class="comment-btn"><mat-icon>delete</mat-icon></small>
        </div>

        <p class="comment-text ">{{feedback.comment}}
            <span *ngIf="feedback.isEdited" class="is-edited">{{'dashboard.feedback.isEdited'  | translate}}</span>
        </p>
    </div>
</ng-template>

<ng-template #editing>
    <div class="comment-container">
        <label class="input-label">{{'dashboard.feedback.editFeedback' | translate}}</label>
        <div class="edit-comment">
            <textarea class="comment-text" autosize [(ngModel)]="feedback.comment" (keypress)="keyPress($event)" maxlength="140"></textarea>
        </div>
        <div class="controls">
            <button class="comment-btn"  [disabled]="feedback.comment.length == 0  || feedback.comment == commentBackup" (click)="send()"> <mat-icon>send</mat-icon></button>
            <small (click)="toggleEdit()" class="comment-btn"><mat-icon>clear</mat-icon></small>
        </div>
    </div>
</ng-template>
