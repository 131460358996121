import { Injectable                                  } from '@angular/core';
import { CanActivate                         } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IdentityService } from '../services/identity.service';
import { AppModel } from '../models/app.model';

@Injectable()
export class AppModelGuard implements CanActivate
{
   constructor(private appModel: AppModel,
       private identityService: IdentityService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (!this.appModel.identity) {
          this.appModel.identity = await this.identityService.getInfo();
       }
       return true;
    }
}
