import { Component, OnInit      } from '@angular/core';
import { AppModel, RoutingService, SaveBarService, AppService, HelpService } from '@app/core';
import { Router } from '@angular/router';
import { Page } from '@app/global';
import { RouteTools } from '@app/tools';
import { ActivatedRoute } from '@angular/router';
import { TonicTranslateService } from "@app/modules/shared/services/tonic.translate.service";
import { NavigationMobileService } from '../../services/navigation.mobile.service';
import { PlanService } from '../../services/plan.service';
import {IPlan} from '../../models/plan';
import {PlanType} from '../../models/plan.type'
@Component({
    selector:    'mobile-menu',
   templateUrl: './mobile-menu.component.html',
   styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit
{
    public plan: IPlan;
    public openedFlyout: boolean = false;
    public resourceLoaded: boolean = false;

    constructor(private route: ActivatedRoute,
        private app: AppModel,
        private routingService: RoutingService,
        private saveBarService: SaveBarService,
        private router: Router,
        private navigationService: NavigationMobileService,
        private tonicTranslateService: TonicTranslateService,
        public helpService: HelpService,
        private planService: PlanService) {}

    public async ngOnInit() {
        if (this.challengeId) {
            this.plan = await this.planService.getPlan(this.challengeId)
            await this.tonicTranslateService.load(this.plan.type);
            
            this.resourceLoaded = true;
        }
    }

    public toggleFlyout() {
        this.openedFlyout = !this.openedFlyout;
    }

    public displaySave(): boolean {
        return this.saveBarService.handler ? true : false;
    }

    public save() {
        this.saveBarService.handler.save();
    }

    public cancel() {
        this.saveBarService.handler.cancel();
    }

    public goToBoard() {
        this.router.navigate([this.routingService.getPath(Page.Dashboard, this.app.language)], {
            queryParamsHandling: "preserve"
        });
    }

    public goToFeed() {
        this.router.navigate([this.routingService.getPath(Page.ViewFeed, this.app.language)], {
            queryParamsHandling: "preserve"
        });
    }

    public goToContribution() {
        this.helpService.showContributionHint = false;
        this.router.navigate([this.routingService.getPath(Page.MyContribution, this.app.language)], {
            queryParamsHandling: "preserve"
        });
   }

    public isBoardActive(): boolean {
       return this.navigationService.isBoardActive();
    }

    public isFeedActive(): boolean {
        return this.navigationService.isFeedActive();
    }

    public isMyContributionActive(): boolean {
        return this.navigationService.isMyContributionActive();
    }

    public get challengeId(): string {
        return  RouteTools.getQueryParams(this.route)['challengeId'];
    }

    public get isContentButtonDisabled(): string{
        if(this.plan.type !== PlanType.Journey){
            return "disabled";
        }
        return null;
    }
}
