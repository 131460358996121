import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { IDocument } from '../../models/document';
import { RouteTools } from '@app/tools';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { AppModel } from '@app/core';
import { NavigationService } from '@app/core';
import { Page, Language } from '@app/global';
@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.css']
})

export class DocumentComponent implements OnInit {
    public documentId: string;
    public document: IDocument;

    constructor(private documentService: DocumentService,
        route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        public app: AppModel,
        private navigationService: NavigationService,
        private titleService: Title) {
        this.documentId = RouteTools.getId(route);
    }

    async ngOnInit() {
        this.document = await this.documentService.getDocument(this.documentId);
        if (!this.document.title) {
            this.navigationService.navigate(Page.Document, Language.Default, this.documentId);
        } else {
            this.titleService.setTitle(this.document.title);
        }
    }

    get documentUrl(): SafeUrl {

        return this.sanitizer.bypassSecurityTrustResourceUrl(`api/document/GetFile?documentId=${this.document.id}&language=${this.app.language}#toolbar=0&view=FitH`);
    }
}
