import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { IFeeling } from '../models/feeling';
import { AppModel } from '@app/core';

@Injectable()
export class FeelingService {

    constructor(private http: HttpClient,
        private app: AppService,
        private appModel: AppModel ) { }

    public getFeelings(): Promise<IFeeling[]> {
        let language = this.appModel.language;
        return this.http.get<IFeeling[]>(`${this.app.baseUrl}api/feeling/list`, { params: { language } }).toPromise();
   }

    public updateSelfEvaluation(commitmentId: string, feelingId: string, evaluation: string) {
        let commitmentInput = {
            commitmentId,
            feelingId,
            evaluation: evaluation.trim(),
            language: this.appModel.language
        }
        return this.http.post<string>(`${this.app.baseUrl}api/dashboard/Commitment/SelfEvaluation/Update`, commitmentInput).toPromise();
    }
}
