<div  class="retro-form" *ngIf="loaded">
    <div class="emotions">
        <div class="emotion-container">
            <span *ngIf="commitment.feeling.emoji" class="feeling" title="{{commitment.feeling.name}}">
                <img class="emoji" src="{{getEmojiUrl(commitment.feeling.emoji)}}">
            </span>

            <mat-form-field>
                <mat-label></mat-label>
                <mat-select id="feelingSelect"
                            [(ngModel)]='commitment.feeling.id'
                            (ngModelChange)="onFeelingChanged($event)"
                            [disabled]="!challengeStarted"
                            class="select-list"
                            placeholder="{{'retro-form.feelingPlaceholder' | translate}}">

                    <mat-option *ngFor="let feeling of feelings" id="feelingOption" value="{{feeling.id}}">
                        <label>
                            <img class="emoji" src="{{getEmojiUrl(feeling.emoji)}}" />
                            <span>{{feeling.name}}</span>
                        </label>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <textarea placeholder="{{'tonic.retro-form.howDoYouFeel' | translate}}" [(ngModel)]='evaluationInternal' [disabled]="!challengeStarted" maxlength="200" autosize></textarea>
    </div>

    <button class="primary" [disabled]="!commitment.feeling.id || !challengeStarted" (click)="save()">{{'retro-form.saveBtn' | translate}}</button>
</div>
