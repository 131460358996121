import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {
    Validators,
    FormService,
    CustomError,
    NotificationService,
    LocalizationService,
    AppModel
} from '@app/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { QuizService } from '../../services/quiz.service';
import { FileValidator } from '@app/modules/shared/directives/required-file.directive'
@Component({
  selector: 'app-quiz-add',
  templateUrl: './quiz-add.component.html',
    styleUrls: ['./quiz-add.component.css', '../../shared/add.shared.css']
})
export class QuizAddComponent {

    public readonly form: FormGroup;
    public  file = new FormControl('', FileValidator.validate);
    public readonly duration = new FormControl('',[Validators.required,Validators.min(0)]);
    public readonly img = new FormControl('', FileValidator.validate);
    public readonly author = new FormControl('',Validators.required);
    public isBusy: boolean = false;

    constructor(public formService: FormService,
        private quizService: QuizService,
        public app: AppModel,
        private notificationService: NotificationService,
        private localizationService: LocalizationService,
        public dialogRef: MatDialogRef<QuizAddComponent>
    )
    {
        this.form = new FormGroup({
            file: this.file,
            duration: this.duration,
            img: this.img,
            author: this.author
        });
    }

    async uploadFile() {
        if (this.form.valid) {
            this.isBusy = true;
            try {
                let result = await this.quizService.uploadQuiz(this.file.value[0],
                    this.imageFile,
                    this.author.value,
                    this.duration.value);

                if (result.fileModelValid) {
                    this.notificationService.showMessage(this.localizationService.getString("quiz-add.upload.success"));
                    this.form.reset();
                    this.dialogRef.close(true);
                } else {
                    this.notificationService.showMessage(this.localizationService.getString("quiz-add.upload.fileError"));
                }
               
            }
            finally {
                this.isBusy = false;

            }
        }
    }

    imageChangedEvent: any = '';
    preview: any = '';
    imageFile: File;

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.preview = event.base64;
        fetch(this.preview)
            .then(res => res.blob())
            .then(blob => {
                this.imageFile = new File([blob], this.img.value[0].name, { type: "image/png" });
            });

    }
}
