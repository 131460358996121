import { Injectable, Injector, isDevMode, PLATFORM_ID } from '@angular/core';
import { isPlatformServer, isPlatformBrowser          } from '@angular/common';
import { SystemTools                                  } from '@app/tools';

@Injectable()
export class AppService
{
    public readonly baseUrl:           string;
    public readonly isDevMode:         boolean;
    public readonly isPlatformServer:  boolean;
    public readonly isPlatformBrowser: boolean;
    public readonly viewId:            string;

    constructor(injector: Injector)
    {
        this.baseUrl = injector.get('BASE_URL');
        if (!this.baseUrl.endsWith('/'))
             this.baseUrl += '/';

        this.isDevMode         = isDevMode();
        this.isPlatformServer  = isPlatformServer(injector.get(PLATFORM_ID));
        this.isPlatformBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));

        this.viewId = SystemTools.newGuid();
    }

    public isMobile(): boolean {
        return window.innerWidth < 1025;
   }
}
