export interface IStatement {
    actor: Agent;
    verb: IVerb;
    object: IStatementObject;
    context?: {
        contextActivities?: {
            parent: Activity[]
        }
    };
    result?: {
        response: string
    };
}

export interface IVerb {
    id: string;
    display: any;
}

export interface IStatementObject {
    objectType: string;
}

export class Agent implements IStatementObject {
    objectType: string;
    mbox: string;

    constructor(mbox: string) {
        this.objectType = 'Agent';
        this.mbox = mbox;
    }
}

export class Activity implements IStatementObject {
    objectType: string;
    id: string;
    definition: IStatementDefinition;

    constructor(id?: string, definition?: IStatementDefinition) {
        this.objectType = 'Activity';
        this.id = id;
        this.definition = definition;
    }
}

export interface IStatementDefinition {
    name: any;
    description: any;
}
