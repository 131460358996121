import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Language, Page                 } from '@app/global';
import { LanguageTools                  } from './language-tools';

export class RouteSnapshotTools
{
    public static getId(route: ActivatedRouteSnapshot): string {
        return route.params['id']; }

    public static getLanguage(route: ActivatedRouteSnapshot): Language {
        return LanguageTools.getLanguageFromCode(route.params['language']); }

    public static getOption(route: ActivatedRouteSnapshot): string {
        return route.params['option']; }

    public static getPage(route: ActivatedRouteSnapshot): Page {
        return route.data['page']; }

    public static getQueryParams(route: ActivatedRouteSnapshot): Params {
        return route.queryParams;
    }

    public static getReturnUrl(route: ActivatedRouteSnapshot): string {
        return route.queryParams['returnUrl'];
    }
}
