import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function TeamsRoutes(teamsComponent?: Type<any>, teamComponent?: Type<any>): Route[] {
    return [
        {
            path: '',
            data: { i18n: 'teams' },
            children: [
               { path: '', component: teamsComponent, data: { page: Page.Teams } },
               { path: ':id', component: teamComponent, data: { page: Page.Team } }
            ]
        }
    ];
}
