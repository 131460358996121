<div *ngIf="app.identity">
    <section class="header">
        <div class="sub-header">
            <h1>{{ 'toolbox.title' | translate }}</h1>

            <div class="theme">
                <mat-icon>filter_list</mat-icon>
                <mat-select [(value)]="selectedTheme" [placeholder]="'toolbox.all-themes' | translate">
                    <mat-option class="option" [value]="null">{{ 'toolbox.all-themes' | translate }}</mat-option>
                    <mat-option class="option" *ngFor="let t of themes" [value]="t">{{t.name}}</mat-option>
                </mat-select>
            </div>

        </div>
    </section>

    <section class="content">
        <app-tool-box-theme-view *ngFor="let toolbox of displayedToolboxes" [theme]="toolbox.theme" [tools]="toolbox.tools"></app-tool-box-theme-view>
    </section>
</div>




