import { Injectable } from '@angular/core';

@Injectable()
export class TimeService
{
    public now: Date;

    constructor()
    {
        this.refresh();
        setInterval(() => { this.refresh(); }, 30000);
    }

    private refresh()
    {
        this.now = new Date();
    }
}
