<section class="theme-container">
    <div class="theme-details">
        <p class="theme">
            {{theme.name}}
        </p>
    </div>
    <section class="tools">
        <app-tool-card *ngFor="let t of tools" [tool]="t"></app-tool-card>
    </section>
</section>

