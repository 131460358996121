import { Injectable          } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from './localization.service';

@Injectable()
export class NotificationService
{
    constructor(private snackBar:     MatSnackBar,
                private localization: LocalizationService) { }

    public showError(message: string, action?: string, onAction?:() => void)
    {
        let ref = this.snackBar.open(message, action ? action : this.localization.getString('global.ok'), { duration: 5000 });
        if (onAction)
            ref.onAction().subscribe(onAction);
    }

    public showMessage(message: string) {
        this.snackBar.open(message, this.localization.getString('global.close'), { duration: 1000 });
    }
}
