import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';


// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function ToolBoxRoutes(sendContentComponent?: Type<any>,
    quizComponent?: Type<any>,
    documentComponent?: Type<any>,
    toolboxViewComponent?: Type<any>,
    toolboxAdminComponent?: Type<any>,
    menuComponent?: Type<any>,
    emptyMenuComponent?: Type<any>): Route[] {
    return [
        {
            path: '',
            data: { i18n: 'toolbox' },
            children: [
                {
                    path: '',
                    children: [
                        {
                            path: '',
                            component: toolboxViewComponent, data: { page: Page.ToolboxView }
                        },
                        {
                            path: '',
                            outlet: 'navigation',
                            component: menuComponent
                        }
                    ]
                },
                {
                    path: 'send',
                    children: [
                        {
                            path: '',
                            component: sendContentComponent, data: { page: Page.Toolbox }
                        },
                        {
                            path: '',
                            outlet: 'navigation',
                            component: menuComponent
                        }
                    ]

                },
                {
                    path: 'quiz/:id',
                    children: [
                        {
                            path: '',
                            component: quizComponent,
                            data: { page: Page.Quiz }
                        },
                        {
                            path: '',
                            outlet: 'navigation',
                            component: emptyMenuComponent
                        }
                    ]
                },
                {
                    path: 'document/:id', 
                    children: [
                        {
                            path: '',
                            component: documentComponent,
                            data: { page: Page.Document }
                        },
                        {
                            path: '',
                            outlet: 'navigation',
                            component: emptyMenuComponent
                        }
                    ]
                },
                {
                    path: 'admin',
                    children: [
                        {
                            path: '',
                            component: toolboxAdminComponent, data: { page: Page.ToolboxAdmin }
                        },
                        {
                            path: '',
                            outlet: 'navigation',
                            component: menuComponent
                        }
                    ]
                }
                
            ]
        }
    ];
}
