import { ITheme } from "./theme";
export interface IChallenge {
    id: string;
    name: string;
    theme: ITheme;
    countDown: number;
    startDate: Date;
    endDate: Date;
    status: ChallengeStatus;
    stake: string;
    successCriteria: string;
    duration: number;
    minPeriodCount : number;
}


export enum ChallengeStatus {
    OnGoing = 'onGoing',
    Extended = 'extended',
    Completed = 'completed',
    Evaluation = 'evaluation',
    Preparation = 'preparation'
}
