import { Component, Input} from '@angular/core';
import { IFeelingCount } from '../../models/analytics/feeling.count';
import { EmoticonService } from '@app/core';
import * as _ from 'lodash';

@Component({
    selector:    'feeling-count',
    templateUrl: './feeling-count.component.html',
    styleUrls: ['./feeling-count.component.css']
})

export class FeelingCountComponent
{
    @Input() values: IFeelingCount[];
    @Input() title:string;
    constructor(private emoticonService: EmoticonService) {
    }
    getEmojiUrl(emoji: string) {
        return this.emoticonService.emojisUrl.get(emoji);
    }
   
    getOpacity(value: number): number {
        let total = _.sum(this.values.map(f => f.count));
        return value / total;
    }

    feelingId(index, item: IFeelingCount) {
        return item.feeling.id;
    }
}
