<div [formGroup]="selfEvaluationFormGroup" class="emotions" *ngIf="loaded">
    <div class="emotion-container">
        <span *ngIf="selectedFeeling" class="feeling" title="{{selectedFeeling.name}}">
            <img class="emoji" src="{{getEmojiUrl(selectedFeeling.emoji)}}">
        </span>

        <mat-form-field>
            <mat-label></mat-label>
            <mat-select id="feelingSelect"formControlName="feeling" class="select-list"
                placeholder="{{'retro-form.feelingPlaceholder' | translate}}">

                <mat-option *ngFor="let feeling of feelings" id="feelingOption" value="{{feeling.id}}">
                    <label>
                        <img class="emoji" src="{{getEmojiUrl(feeling.emoji)}}" />
                        <span>{{feeling.name}}</span>
                    </label>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <textarea placeholder="{{'tonic.retro-form.howDoYouFeel' | translate}}" 
    formControlName="evaluation" [readonly]="!feeling.valid"
        maxlength="200" autosize></textarea>
</div>