import { NgModule } from '@angular/core';

import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    imports: [
        MatNativeDateModule,
        MatSnackBarModule
    ],
    exports: [
        MatNativeDateModule,
        MatSnackBarModule
    ]
})
export class MaterialServicesModule { }
