import { ActivatedRoute, Params } from '@angular/router';
import { Language, Page         } from '@app/global';
import { RouteSnapshotTools     } from './route-snapshot-tools';

export class RouteTools
{
    public static getReturnUrl(route: ActivatedRoute): string {
        return RouteSnapshotTools.getReturnUrl(route.snapshot);
    }
    public static getId(route: ActivatedRoute): string {
        return RouteSnapshotTools.getId(route.snapshot); }

    public static getLanguage(route: ActivatedRoute): Language {
        return RouteSnapshotTools.getLanguage(route.snapshot); }

    public static getOption(route: ActivatedRoute): string {
        return RouteSnapshotTools.getOption(route.snapshot); }

    public static getPage(route: ActivatedRoute): Page {
        return RouteSnapshotTools.getPage(route.snapshot); }

    public static getQueryParams(route: ActivatedRoute): Params {
        return RouteSnapshotTools.getQueryParams(route.snapshot); }
}
