export const environment = {
   production: true,
   applicationInsights: {
      instrumentationKey: '64bbf901-a481-4a9a-aa4a-dd9905a34550'
   },
   cmsUrl:"https://cms.test.teamtonic.com/",
   oldHeatmapUrl:"https://heatmap.boostalab.com/",
   google: {
      ClientId: "466387560188-flmtt21ofm5dce3mi8fjahljnqgtbs8e.apps.googleusercontent.com",
      googleAnalyticsTrackingId: "G-YLDN2F786J"
    }
};
