<section>
    <mat-icon class="material-icons-outlined">thumb_up_alt</mat-icon>

    <div class="form">
        <span class="profil-picture">
            <profile-picture *ngIf="participant" [person]="participant.person"></profile-picture>
        </span>
        <mat-form-field >
            <mat-label></mat-label>
            <mat-select id="feelingSelect" [disabled]="!myParticipation" [(value)]="participant" class="select-list"
                        placeholder="{{'dashboard.feedback.recipient' | translate}}">
                <mat-option *ngFor="let p of availableParticipant" id="feelingOption" [value]="p">
                    <label>
                        <span>{{p.person.firstName}} {{p.person.lastName}}</span>
                    </label>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <textarea
                  [(ngModel)]='comment'
                  #feedbackForm maxlength="140"
                  (keydown.enter)= "send()"placeholder="{{placeHolder}}"
                      [disabled]="!myParticipation"></textarea>
        </div>

        <button class="primary" [disabled]="!myParticipation" (click)="send()">{{'dashboard.saveBtn' | translate}}</button>
    </div>
</section>

