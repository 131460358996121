<div *ngIf="app.identity" [class.hidden-top-bar]="openedFlyout">
    <div class="noprint">
        <router-outlet name="navigation"></router-outlet>

        <div class="navigation-bar" *ngIf="navigating">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>

<button *ngIf="app.identity && showNav()" class="challenge-nav-button display-phone secondary" (click)="toggleNav()" [class.hidden-top-bar]="openedFlyout">
    <mat-icon class="material-icons-outlined">menu</mat-icon>
</button>

<div class="content" [hidden]="navigating">
    <router-outlet></router-outlet>
</div>

<div *ngIf="app.identity && showNav()" class="flyout challenge-nav display-phone" [class.opened-nav]="openedNav" [class.closed-nav]="!openedNav">
    <router-outlet name="navigation-mobile-side-right"></router-outlet>
</div>

<div *ngIf="app.identity && showNav()" class="bottom-nav display-phone" [class.flattened]="openedFlyout">
    <router-outlet name="navigation-mobile-bottom"></router-outlet>
</div>

<div class="hide-phone">
    <router-outlet name="footer"></router-outlet>
</div>
