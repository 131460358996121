import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';

import { IPeriodConfidence } from "../../models/analytics/period.confidence";
import { LocalizationService } from '@app/core';
import * as _ from 'lodash';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

import { PlanType } from "../../models/plan.type";

@Component({
    selector:    'confidence-chart',
    templateUrl: './confidence-chart.component.html',
    styleUrls: ['./confidence-chart.component.css', './confidence-chart.component.mobile.css'],
})

export class ConfidenceChartComponent implements OnInit
{
    @Input() planType: PlanType;
    @Input() showChartOnly: boolean = false;

    public chartLabels: Label[];
    public chartType = 'bar';
    public chartLegend = true;
    @Input() periods : IPeriodConfidence[];
  

    constructor(private localization: LocalizationService) {
    }

    ngOnInit() {
        if (this.periods)
            this.refresh();
    }

    ngOnChanges() {
        if (this.periods)
            this.refresh();
    }


    private  refresh() {

        let serieSum = {
            label: this.localization.getString("dashboard.evaluation.confidenceLevel"),
            data: []
        }
        let serieRespondentCount = {
            label: this.localization.getString("dashboard.evaluation.numberOfRespondents"),
            hidden:true,
            data: []
        }

        this.lineChartLabels = [];
       
        this.periods.forEach((p,index) => {
            this.lineChartLabels.push(`${this.getFormattedPeriod(index + 1)}`);
            serieSum.data.push(p.sum);
            serieRespondentCount.data.push(p.respondentCount);
        });

        this.lineChartData = [];
        this.lineChartData.push(serieSum);
        this.lineChartData.push(serieRespondentCount);
    }

    public lineChartData: ChartDataSets[] = [];
    public lineChartLabels: Label[] = [];
    public lineChartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [
                {
                    ticks: {
                        fontFamily: 'Barlow',
                        fontSize: 11
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.localization.getString("dashboard.evaluation.periodLength"),
                        fontFamily: 'Barlow',
                        fontSize: 11,
                        fontColor: '#8f8f8f'
                    }
                }
            ],
            yAxes: [
                {
                    
                    id: 'y-axis-0',
                    position: 'left',
                    ticks: {
                        min: -1.5,
                        max: 3.5,
                        stepSize: 5,
                        fontFamily: 'Barlow',
                        display: false,

                    },
                    gridLines: {
                        drawTicks: false,
                        zeroLineColor: 'transparent'
                    }
                }
            ]
        },
        annotation: {
            annotations: [],
        },
        legend: {
            display: false
        },
        tooltips: {
            custom: function(tooltip) {
                if (!tooltip) return;
                // disable displaying the color box;
                tooltip.displayColors = false;
            },
            callbacks: {
                // use label callback to return the desired label
                label: function (tooltipItem, data) {
                    return `${data.datasets[1].label}: ${data.datasets[1].data[tooltipItem.index]}`;
                }
                
            }
        }

    };

    public lineChartType = 'line';
    public chartData: ChartDataSets[] = [];

    public lineChartColors: Color[] = [
        { // green
            backgroundColor: 'rgba(91,209,134,0)',
            borderColor: 'rgba(91,209,134,1)',
            pointBackgroundColor: 'rgba(91,209,134,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: 'rgba(54,54,75,0.8)',
            pointHoverBorderColor: 'rgba(54,54,75,0.8)'
        }
    ];

    getFormattedPeriod(index: number) : string {
        return `${index}`;
    }

    getQualitativeValue(): string {
        if (this.displayNotApplicableText()) return this.localization.getString('dashboard.evaluation.graph.empty.title');

       let slope = this.getSlope();

       if (this.getLatest().sum === 3) return this.getTitle("OnAReussiPlaceholder");

      if (slope <= -1) return this.getTitle("onReculePlaceholder");
      if (slope > -1 && slope < 1) return this.getTitle("OnBougePasPlaceholder");
      if (slope >= 1 && slope < 2) return this.getTitle("OnABesoinDeMotivationPlaceholder");
      if (slope >= 2) return this.getTitle("OnSApprochePlaceholder");
   }

   getQualitativeDescription(): string {
       if (this.displayNotApplicableText()) return this.localization.getString('dashboard.evaluation.graph.empty.description');

       let slope = this.getSlope();

       if (this.getLatest().sum === 3) return this.getDescription("OnAReussiPlaceholder");

      if (slope <= -1) return this.getDescription("onReculePlaceholder");
      if (slope > -1 && slope < 1) return this.getDescription("OnBougePasPlaceholder");
      if (slope >= 1 && slope < 2) return this.getDescription("OnABesoinDeMotivationPlaceholder");
      if (slope >= 2) return this.getDescription("OnSApprochePlaceholder");

    }

    displayNotApplicableText(): boolean {
        return this.periods.length === 0 || (this.periods.length === 1 && this.periods[0].respondentCount === 0);
    }

   private getSlope():number {
      let latest = this.getLatest();
      let previous = this.periods[this.periods.length - 2];

      if (!previous) return null;

       return latest.sum - previous.sum;
   }

   private getLatest(): IPeriodConfidence {
       return this.periods[this.periods.length - 1];
   }

   private getDescription(key:string) {
      return this.localization.getString('dashboard.evaluation.graph.' + this.planType + '.' + key + '.description');
   }

   private getTitle(key: string) {
       return this.localization.getString('dashboard.evaluation.graph.' + this.planType + '.' + key + '.title');
   }

   get showQualitativeDescription(): boolean {
       return this.planType === PlanType.TeamChallenge;
   }

   get hasResponses(): boolean{
       return _.sum(this.periods.map(p =>p.respondentCount)) > 0;
   }
}

