import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IQuiz } from '../../models/quiz';
import { AnsweredQuestion } from '../../models/answered.question';
import { IQuestionCategory } from '../../models/question.category';
import { ILocalizationService } from '../../services/localization.service';
import { IQuestion } from '../../models/question';
import { IQuizResult } from '../../models/quiz.result';
import { IAnswer } from '../../models/answer';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
@Component({
    selector:    'quiz-lib',
    templateUrl: './quiz-lib.component.html',
    styleUrls: ['./quiz-lib.component.css',
        '../shared/theme.teamtonic.css',
        './quiz-lib.component.teamtonic.css'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),
            state('out', style({ opacity: 1 })),

            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            // fade in when created. this could also be written as transition('void => *')
            transition('in => out', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            transition('out => in', [
                style({ opacity: 0 }),
                animate(600)
            ])
        ])
    ]
})
export class QuizLibComponent implements OnInit
{
    @Input() public localization: ILocalizationService;
    @Input() public quiz: IQuiz;
    @Output() onQuizCompleted = new EventEmitter<IQuizResult[]>();
    public answeredQuestion: AnsweredQuestion;
    public firstAnsweredQuestion: AnsweredQuestion;
    public transit: boolean = false;
    public started: boolean = false;

    ngOnInit() {
        if (!this.quiz.welcome) {
            this.started = true;
        }
        this.create();
    }

    previous() {
        this.answeredQuestion = this.answeredQuestion.previous;
        this.transit = !this.transit;
    }

    next() {
        
        this.answeredQuestion = this.answeredQuestion.next;
        this.transit = !this.transit;
    }

    onChange(value: IAnswer) {
        this.answeredQuestion.answer = value;
        if (this.answeredQuestion.next) {
            this.next();
        }
    }


    create() {
        let answeredQuestions = this.quiz.randomize
            ? this.shuffle(new Array(...this.quiz.questions)).map(q => new AnsweredQuestion(q))
            : this.quiz.questions.map(q => new AnsweredQuestion(q));

        for (let i = 0; i < answeredQuestions.length; i++) {
            answeredQuestions[i].index = i + 1;
            answeredQuestions[i].previous = answeredQuestions[i - 1];
            answeredQuestions[i].next = answeredQuestions[i + 1];
        }
        this.firstAnsweredQuestion = this.answeredQuestion = answeredQuestions[0];
    }

    //Code emprunté: Code utilisant l'algorithme Fisher-Yates afin de générer l'ordre des question aléatoirement
    //Source: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    shuffle(questions: IQuestion[]) {
        var currentIndex = questions.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = questions[currentIndex];
            questions[currentIndex] = questions[randomIndex];
            questions[randomIndex] = temporaryValue;
        }

        return questions;
    }

    complete() {
        this.onQuizCompleted.emit(this.results);
    }

    get results(): IQuizResult[] {
        let results = new Map<IQuestionCategory, number>();
        let categories = this.quiz.questions.map(q => q.category);

        let iterator = this.firstAnsweredQuestion;
        while (iterator) {
            let count = results.get(categories.find( c => c.id === iterator.question.category.id)) | 0;
            if (iterator.answer) {

                count += iterator.answer.ponderation;
            }
            results.set(categories.find(c => c.id === iterator.question.category.id), count );
            iterator = iterator.next;
        }

        let quizResults: IQuizResult[] = [];

        results.forEach((value: number, key: IQuestionCategory) => {
            quizResults.push(<IQuizResult>{ category: key, value, percentage: value / Math.max(...key.results.map(r => r.high)) });
        });

        return quizResults;
    }

    completed(): boolean {

        if (!this.answeredQuestion.next && this.answeredQuestion.answer)
            return true;

        return false;
    }

    start() {
        this.started = true;
    }
}
