import { Component      } from '@angular/core';
import { AppModel       } from '@app/core';
import { RoutingService } from '@app/core';
import { Page, Role     } from '@app/global';

@Component({
    selector:    'main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.css', './main-menu.component.mobile.css']
})
export class MainMenuComponent
{
    constructor(private app:            AppModel,
                private routingService: RoutingService) { }

    getDefaultUrl(): string {
        if (this.app.identity.roles.includes(Role.Organizer)) {
            return this.routingService.getPathToPage(Page.DefaultOrganizer);
        }

        return this.routingService.getPathToPage(Page.Default);
    }

   getAdminPortalUrl(): string {
      if (this.app.identity.roles.includes(Role.Administrator)) {
          return this.routingService.getPathToPage(Page.Organisations);
      }

      if (this.app.identity.roles.includes(Role.Organizer)) {
          return this.routingService.getPathToPage(Page.Plans);
      }

      return this.getDefaultUrl();
    }

   getAdminToolboxUrl():string {
       return this.routingService.getPathToPage(Page.ToolboxAdmin);
   }

   getToolboxUrl(): string {
       return this.routingService.getPathToPage(Page.ToolboxView);
   }

    getFAQUrl(): string {
        return this.routingService.getPathToPage(Page.Faq);
    }

   showAdminToolbox(): boolean {
       return this.app.identity != null && this.app.identity.roles.includes(Role.Administrator);
   }
   showAdminPortal(): boolean {
      return this.app.identity != null && (this.app.identity.roles.includes(Role.Administrator) || this.app.identity.roles.includes(Role.Organizer));
    }
}
