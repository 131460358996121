import { Injectable } from '@angular/core';
import { AppModel, RoutingService } from '@app/core';
import {Location } from '@angular/common';
import { Page } from '@app/global';
import { Subject } from 'rxjs';

@Injectable()
export class NavigationMobileService {
    public toogleSideNavigation = new Subject();
   constructor(
      private app: AppModel,
      private location: Location,
      private routingService: RoutingService) { }


   public isBoardActive(): boolean {
       return this.location.path().split('?')[0] === this.routingService.getPath(Page.Dashboard, this.app.language);
   }

   public isFeedActive(): boolean {
       return this.location.path().split('?')[0] === this.routingService.getPath(Page.ViewFeed, this.app.language);
   }

   public isMyContributionActive(): boolean {
       return this.location.path().split('?')[0] === this.routingService.getPath(Page.MyContribution, this.app.language);
   }

}
