import { Injectable } from '@angular/core';
import { IParticipant as DashboardParticipant } from '@app/modules/dashboard/models/participant';
import { IParticipant as ContributionParticipant } from '@app/modules/contribution/models/participant';

@Injectable()
export class ParticipantService {
    public isContributionCompleted(participant: DashboardParticipant | ContributionParticipant): boolean {
        return participant.commitment &&
            participant.commitment.publicId &&
            participant.commitment.description &&
            participant.commitment.description.trim().length > 0;
    }
}
