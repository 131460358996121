/*
 * Public API Surface of quiz-lib
 */

export * from './lib/components/quiz-lib/quiz-lib.component';
export * from './lib/quiz-lib.module';

export * from './lib/models/answer';
export * from './lib/models/question.category';
export * from './lib/models/answered.question';
export * from './lib/models/question';
export * from './lib/models/quiz';
export * from './lib/models/question.category.result';
export * from './lib/models/quiz.result';
