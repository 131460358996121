import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function OnBoardingRoutes(signUpComponent?: Type<any>,
    loginComponent?: Type<any>, 
    contributionComponent?: Type<any>,
    logoHeaderComponent?: Type<any>,
    finishedComponent?: Type<any>, 
    onBoardingGuard?: Type<any>): Route[] {
    return [
        {
            path: 'signup',
            data: { i18n: 'onboarding.signup' },
            children: [
                { path: ':id',    component: signUpComponent, data: { page: Page.OnBoardingSignUp }}
            ]
        },
        {
            path: 'enter',
            data: { i18n: 'onboarding.login' },
            children: [
                { path: ':id',    component: loginComponent, data: { page: Page.OnBoardingLogin }}
            ]
        },
        {
            path: 'contribution',
            data: { i18n: 'onboarding.contribution' },
            children: [
                { path: ':id',  canActivate:[onBoardingGuard],  component: contributionComponent, data: { page: Page.OnBoardingContribution } },
                {
                    path: '',
                    outlet: 'navigation',
                    component: logoHeaderComponent
                } 

            ]
        },
        {
            path: 'finish',
            data: { i18n: 'onboarding.finish' },
            children: [
                { path: ':id', canActivate:[onBoardingGuard],   component: finishedComponent, data: { page: Page.OnBoardingFinished} },
                {
                    path: '',
                    outlet: 'navigation',
                    component: logoHeaderComponent
                } 
            ]
        }
    ];
}
