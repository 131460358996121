import { Component, OnInit, Inject } from '@angular/core';
import { DocumentService } from '../../services/document.service'
import { FormGroup, FormControl } from '@angular/forms';
import { IDocument } from '../../models/document';
import { Language } from '@app/global';
import {
    AppModel,
    TranslateService,
    Validators,
    FormService
} from '@app/core';

import { NotificationService } from "@app/core/services/notification.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITool } from '../../models/tool';
import { FileValidator } from '@app/modules/shared/directives/required-file.directive'
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
    styleUrls: ['./document-add.component.css', '../../shared/add.shared.css']
})
export class DocumentAddComponent implements OnInit {

    public readonly fileFr = new FormControl('', FileValidator.validate);
    public readonly titleFr = new FormControl('', Validators.required);

    public readonly fileEn = new FormControl('');
    public readonly titleEn = new FormControl('');

    public readonly duration = new FormControl('',[Validators.required,Validators.min(0)]);
    public readonly img = new FormControl('', FileValidator.validate);
    public readonly author = new FormControl('',Validators.required);
    public isBusy: boolean = false;
    public readonly form: FormGroup;
    constructor(private documentService: DocumentService,
        public formService: FormService,
        private appModel: AppModel,
        private notificationService: NotificationService,
        public dialogRef: MatDialogRef<DocumentAddComponent>) {

        this.form = new FormGroup({
            titleFr: this.titleFr,
            fileFr: this.fileFr,
            duration: this.duration,
            img: this.img,
            author: this.author,
            fileEn: this.fileEn,
            titleEn: this.titleEn
        });

    }

  ngOnInit() {
  }

    async uploadFileToActivity() {
        if (this.form.valid) {
            this.isBusy = true;
            let documentfr = this.createDocument(null, Language.French);

            try {
                let id = await this.documentService.add(documentfr, this.fileFr.value[0], this.imageFile);

                if (this.fileEn && this.titleEn.value) {
                    let documentEn = this.createDocument(id, Language.English);
                    await this.documentService.updateFileLanguage(documentEn, this.fileEn.value[0]);
                }
                this.notificationService.showMessage("Success");
                this.dialogRef.close(true);

                this.form.reset();
            } finally {
                this.isBusy = false;
            }
          
        }

    }

    private createDocument(id: string | null, language: Language):IDocument {
        return <IDocument>{
            id,
            title: language === Language.French ? this.titleFr.value : this.titleEn.value,
            author: this.author.value,
            durationInMinutes: this.duration.value,
            language: language
        };
    }


    imageChangedEvent: any = '';
    preview: any = '';
    imageFile: File;

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.preview = event.base64;
        fetch(this.preview)
            .then(res => res.blob())
            .then(blob => {
                this.imageFile = new File([blob], this.img.value[0].name, { type: "image/png" });
            });
      
    }
}
