import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/core';
import { INavigationNode } from '../models/navigationNode';
@Injectable()
export class ChallengeNavService
{
   constructor(private http: HttpClient,
       private app: AppService) {

   }
   
   public getUserOrganizationChallenges(): Promise<INavigationNode[]> {
      return this.http.get<INavigationNode[]>(`${this.app.baseUrl}api/challenges/UserOrganizationChallenges`).toPromise();
   }
   

}
