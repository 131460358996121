import { Injectable               } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BusyDialogComponent      } from '../components/busy-dialog/busy-dialog.component';
import {  Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
@Injectable()
export class BusyService
{
    private readonly DELAY        = 1000;
    private readonly MIN_DURATION = 1000;

    private count = 0;

    private busyDialogRef:  MatDialogRef<any>;
    private delayDialogRef: MatDialogRef<any>;
    private delayTimer:     Subscription;
    private startTimer:     Subscription;

    constructor(private dialog: MatDialog) { }

    public begin()
    {
        if (this.count === 0)
        {
            // Using a delay of 0 will yield the opening of the dialog at the end of the JavaScript processing loop
            // so method can be called in any component initialization lifecycle hooks (ex: OnInit) without throwing ExpressionChangedAfterItHasBeenCheckedError
            this.startTimer = of(0).pipe(delay(0)).subscribe(() =>
            {
                this.clear();

                this.delayDialogRef = this.dialog.open(BusyDialogComponent, { backdropClass: 'busy-delay-backdrop', panelClass: 'busy-delay-panel' });
                this.delayDialogRef.disableClose = true;

                this.delayTimer = of(0).pipe(delay(this.DELAY)).subscribe(() =>
                {
                    this.clear();

                    this.busyDialogRef = this.dialog.open(BusyDialogComponent);
                    this.busyDialogRef.disableClose = true;

                    of(0).pipe(delay(this.MIN_DURATION)).subscribe(() => { this.end(); });
                    this.count++;
                });
            });
        }

        this.count++;
    }

    private clear()
    {
        if (this.startTimer) {
            this.startTimer.unsubscribe();
            this.startTimer = null;
        }

        if (this.delayTimer) {
            this.delayTimer.unsubscribe();
            this.delayTimer = null;
        }

        if (this.delayDialogRef) {
            this.delayDialogRef.close();
            this.delayDialogRef = null;
        }
    }

    public end(): Promise<void>
    {
        this.clear();

        if (this.count > 0)
            this.count--;

        if (this.busyDialogRef)
        {
            if (this.active)
                return this.busyDialogRef.beforeClosed().toPromise();

            this.busyDialogRef.close();
            this.busyDialogRef = null;
        }

        return Promise.resolve();
    }

    public async wait<T>(promise: Promise<T>): Promise<T>
    {
        this.begin();
        try
        {
            return await promise;
        }
        finally
        {
            await this.end();
        }
    }

    public get active(): boolean
    {
        return this.count > 0;
    }

    public get visible(): boolean
    {
        return this.busyDialogRef != null;
    }
}
