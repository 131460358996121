import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
} from '@angular/animations';
import { ILocalizationService } from '../../services/localization.service';
@Component({
  selector: 'enl-quiz-welcome',
  templateUrl: './quiz-welcome.component.html',
    styleUrls: ['./quiz-welcome.component.css', './quiz-welcome.component.teamtonic.css'],
  animations: [
      // the fade-in/fade-out animation.
      trigger('simpleFadeAnimation', [

          // the "in" style determines the "resting" state of the element when it is visible.
          state('in', style({ opacity: 1 })),
         

          transition(':enter', [
              style({ opacity: 0 }),
              animate(600)
          ])
      ])
  ]
})
export class QuizWelcomeComponent implements OnInit {
    constructor() { }
    @Input() public localization: ILocalizationService;
    @Output() public onStart = new EventEmitter<void>();
    @Input() welcome: string;
    ngOnInit() {
    }
    start() {
        this.onStart.emit();
    }
}
