import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function AssistanceRoutes(faqComponent?: Type<any>): Route[] {
    return [
        {
            path: 'faq',
            data: { i18n: 'faq' },
            children: [
                { path: '',    component: faqComponent, data: { page: Page.Faq } }
            ]
        }
    ];
}
