<svg  width="140" height="280"  viewBox="0 0 224 480">
    <svg:g transform="translate(112, 0)">
        <svg:text transform="translate(0, 82)"
                  [style.textAnchor]="'middle'"
                  font-size="24"
                  fill="#5f5f5f"
                  alignment-baseline="after-edge">
            {{localization.getString('results.linearGauge.topLabel')}}
        </svg:text>
        <svg:g transform="translate(-8, 120)">
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(251, 158, 54);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(254, 63, 53);stop-opacity:1" />
                </linearGradient>
            </defs>
            <rect width="16" height="240" fill="url(#grad1)" />
        </svg:g>


        <svg:g transform="translate(0, 360)">
            <!--set the group at the end of the gauge-->
            <svg:g transform="translate(-32.5, -9.84)">
                <!--adjust bar at the end of the gauge-->
                <svg:g [attr.transform]="transformGaugeScale">
                    <!--set the value on the gauge-->
                    <rect x="8.8" y="4.84" style="fill: none" width="48" height="10" />
                    <rect x="12.8" y="8.84" style="fill-rule: evenodd; clip-rule: evenodd; fill: #5f5f5f;" width="40" height="2" />
                    <path style="fill-rule: evenodd; clip-rule: evenodd; fill: #5f5f5f;" d="M52.25,11.4c-0.48-0.38-0.75-0.95-0.75-1.56c0-0.61,0.28-1.18,0.75-1.56c2.48-1.99,6.81-5.45,9.8-7.84   c0.6-0.48,1.42-0.57,2.12-0.24C64.86,0.53,65.3,1.23,65.3,2c0,4.4,0,11.28,0,15.68c0,0.77-0.44,1.47-1.13,1.8   c-0.69,0.34-1.52,0.24-2.12-0.24C59.06,16.85,54.74,13.39,52.25,11.4z" />
                    <path style="fill-rule: evenodd; clip-rule: evenodd; fill: #5f5f5f;" d="M13.05,11.4c0.47-0.38,0.75-0.95,0.75-1.56c0-0.61-0.28-1.18-0.75-1.56c-2.49-1.99-6.81-5.45-9.8-7.84   C2.65-0.04,1.83-0.13,1.13,0.2C0.44,0.53,0,1.23,0,2c0,4.4,0,11.28,0,15.68c0,0.77,0.44,1.47,1.13,1.8   c0.69,0.34,1.51,0.24,2.12-0.24C6.24,16.85,10.56,13.39,13.05,11.4z" />
                </svg:g>
            </svg:g>
        </svg:g>
       

        <svg:text transform="translate(0, 398)"
                  [style.textAnchor]="'middle'"
                  font-size="24"
                  fill="#5f5f5f"
                  alignment-baseline="before-edge">
            {{localization.getString('results.linearGauge.bottomLabel')}}
        </svg:text>
        </svg:g>
</svg>

