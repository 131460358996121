import { Injectable       } from '@angular/core';
import { TranslateService } from './translate.service';

@Injectable()
export class LocalizationService
{
    constructor(private translate: TranslateService) { }

    public boolToString(value: boolean): string
    {
        return value === true  ? this.getString('global.yes') :
               value === false ? this.getString('global.no')  :
                                 '';
    }

    public getString(key: string, data?: any): string
    {
        return this.translate.instant(key, data);
    }
    public exists(key: string): boolean
    {
        return this.translate.exists(key);
    }
}
