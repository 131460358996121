import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { AppModel, TranslateService } from '@app/core';
import { PlanType } from '../models/plan.type';


@Injectable()
export class TonicTranslateService {

    constructor(private http: HttpClient,
        private app: AppService,
        private appModel: AppModel,
        private translateService: TranslateService) { }

    public async loadFromChallengeId(challengeId: string): Promise<void> {
        let planType = await this.getPlanType(challengeId);
  
        return this.load(planType);
    }

    public async load(planType: PlanType): Promise<void> {

        switch(planType){
            case PlanType.TeamChallenge:
                return this.translateService.loadAndReplace(this.appModel.language, "tonic.team");
            case PlanType.Training:
                 return this.translateService.loadAndReplace(this.appModel.language, "tonic.training");
            case PlanType.Journey:
                 return this.translateService.loadAndReplace(this.appModel.language, "tonic.journey");
        }

        throw Error("Unable to load tonic resources");
    }

    private getPlanType(challengeId: string): Promise<PlanType> {

        return this.http.get<PlanType>(`${this.app.baseUrl}api/challenges/GetPlanType`, {
            params: {
                challengeId
            }
        }).toPromise();
    }
}
