<section>
    <h1>{{ 'document-add.header' | translate }}</h1>

    <form [formGroup]="form">
        <div class="languages-split">
            <div class="fr">
                <h2>{{ 'document-add.french' | translate }}</h2>
                <div>
                    <mat-form-field>
                        <mat-label>{{ 'document-add.title.text' | translate }}</mat-label>
                        <input matInput
                               [formControl]="titleFr"
                               placeholder="{{ 'document-add.title.placeholder' | translate }}"
                               type="text" />
                        <mat-error class="error-message" *ngIf="titleFr.invalid">{{ formService.getErrorMessage(titleFr) }}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label>{{ 'document-add.document' | translate }}</label>
                    <input [formControl]="fileFr"
                           type="file"
                           id="fileFr"
                           accept=".pdf,application/pdf">
                    <mat-error class="error-message" *ngIf="fileFr.invalid">{{ formService.getErrorMessage(fileFr) }}</mat-error>
                </div>
            </div>
            <div class="en">
                <h2>{{ 'document-add.english' | translate }}</h2>
                <div>
                    <mat-form-field>
                        <mat-label>{{ 'document-add.title.text' | translate }}</mat-label>
                        <input matInput [formControl]="titleEn"
                               placeholder="{{ 'document-add.title.placeholder' | translate }}"
                               type="text" />
                        <mat-error class="error-message" *ngIf="titleEn.invalid">{{ formService.getErrorMessage(titleEn) }}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label>{{ 'document-add.document' | translate }}</label>
                    <input [formControl]="fileEn"
                           type="file"
                           id="fileEn"
                           accept=".pdf,application/pdf">
                    <mat-error class="error-message" *ngIf="fileEn.invalid">{{ formService.getErrorMessage(fileEn) }}</mat-error>
                </div>
            </div>
        </div>

        <h2>{{ 'document-add.informations' | translate }}</h2>

        <div class="general">
            <div>
                <label>{{ 'document-add.image' | translate }}</label>
                <div class="image-cropping-container">
                    <div class="image-input">
                        <input id="img" style="display: block"
                               type="file"
                               (change)="fileChangeEvent($event)"
                               [formControl]="img"
                               accept="image/x-png,image/jpeg" />
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                                       [maintainAspectRatio]="true"
                                       cropperMinWidth="120"
                                       cropperMinHeight="120"
                                       format="png"
                                       style="max-width: 65%; max-height: 65%"
                                       (imageCropped)="imageCropped($event)"></image-cropper>
                    </div>

                    <div class="image-preview">

                        <img [src]="preview" style="width: 120px" />
                    </div>
                </div>
                <mat-error class="error-message" *ngIf="img.invalid">{{ formService.getErrorMessage(img) }}</mat-error>
            </div>
          
            <div class="columns">
                <div>
                    <mat-form-field>
                        <mat-label>{{ 'document-add.author' | translate }}</mat-label>
                        <input matInput [formControl]="author" type="text" maxlength="255" />
                        <mat-error class="error-message" *ngIf="author.invalid">{{ formService.getErrorMessage(author) }}</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>{{ 'document-add.requiredTime' | translate }}</mat-label>
                        <input matInput [formControl]="duration" type="number" />
                        <mat-error class="error-message" *ngIf="duration.invalid">{{ formService.getErrorMessage(duration) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>


        <button [disabled]="!form.valid || isBusy" (click)="uploadFileToActivity()" class="primary">{{ 'document-add.add-button' | translate }}</button>
    </form>
</section>
