<div *ngIf="app.identity">
    <div *ngIf="loaded" class="centered">
        <h1 class="content-header">{{plan.name}}</h1>
        <h2 class="content-header">{{plan.description}}</h2>
        <div class="content">
            <h3 class="content-header">{{ 'send-tool.title' | translate }}</h3>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div>
                    <mat-form-field class="input">
                        <input matInput [formControl]="url" type="url" autofocus>
                        <mat-error *ngIf="url.invalid">{{ formService.getErrorMessage(url) }}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-select [formControl]="theme">
                            <mat-option *ngFor="let theme of plan.themes" [value]="theme.id">
                                {{theme.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="theme.invalid">{{ formService.getErrorMessage(theme) }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="input">
                    <button mat-raised-button class="button primary" color="warn" type="submit" [disabled]="isBusy || !form.valid">Send</button>
                </div>
            </form>
        </div>
    </div>
</div>
