import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDocument } from '../models/document';
import { AppService, AppModel } from '@app/core';
import { Observable } from "rxjs";
@Injectable()
export class DocumentService {
    constructor(private http: HttpClient,
        private appModel: AppModel,
        private app: AppService) {
    }


    public  getDocument(documentId: string): Promise<IDocument> {

        return this.http.get<IDocument>(`${this.app.baseUrl}api/document/GetMetadata`,
            {
                params: {
                    documentId,
                    language: this.appModel.language
                }
            }).toPromise();
    }

    public getAll(): Promise<IDocument[]> {

        return this.http.get<IDocument[]>(`${this.app.baseUrl}api/document/GetAll`,
            {
                params: {
                    language: this.appModel.language
                }
            }).toPromise();
    }
    public add(doc: IDocument, file: File, img: File): Promise<string> {
  
        const formData: FormData = new FormData();
        formData.append('fileFr', file, file.name);
        formData.append('img', img, img.name);

        formData.append('title', doc.title.trim());
        formData.append('author', doc.author.trim());
        formData.append('durationInMinutes', doc.durationInMinutes.toString());
        formData.append('language', doc.language.toString());
        return this.http
            .post<string>(`${this.app.baseUrl}api/document/add`, formData).toPromise();
    }

    public updateFileLanguage(doc: IDocument, file: File): Promise<void> {

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        formData.append('id', doc.id);
        formData.append('title', doc.title.trim());
        formData.append('author', doc.author.trim());
        formData.append('durationInMinutes', doc.durationInMinutes.toString());
        formData.append('language', doc.language.toString());
        return this.http.post<void>(`${this.app.baseUrl}api/document/UpdateFile`, formData).toPromise();
    }

    public delete(documentId: string): Promise<void> {
        return this.http
            .post<void>(`${this.app.baseUrl}api/document/delete`, null,
                {
                    params: {
                        documentId
                    }
                }
        ).toPromise();
    }
}
