<section>
    <h1>{{ 'quiz-add.header' | translate }}</h1>
    
    <form [formGroup]="form">
        <div class="general">
            <div>
                <label>{{ 'quiz-add.file' | translate }}</label>
                <input [formControl]="file"
                       type="file"
                       id="file"
                       accept=".json,application/json">
                <mat-error class="error-message" *ngIf="file.invalid">{{ formService.getErrorMessage(file) }}</mat-error>
            </div>

            <div>
                <label>{{ 'quiz-add.image' | translate }}</label>
                <div class="image-cropping-container">
                    <div class="image-input">
                        <input id="img" style="display: block"
                               type="file"
                               (change)="fileChangeEvent($event)"
                               [formControl]="img"
                               accept="image/x-png,image/jpeg" />
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                                       [maintainAspectRatio]="true"
                                       cropperMinWidth="120"
                                       cropperMinHeight="120"
                                       format="png"
                                       style="max-width: 65%; max-height: 65%"
                                       (imageCropped)="imageCropped($event)"></image-cropper>
                    </div>

                    <div class="image-preview">
                        
                        <img [src]="preview" style="width: 120px" />
                    </div>
                </div>
                <mat-error class="error-message" *ngIf="img.invalid">{{ formService.getErrorMessage(img) }}</mat-error>
            </div>

            <div class="columns">
                <div>
                    <mat-form-field>
                        <mat-label>{{ 'quiz-add.author' | translate }}</mat-label>
                        <input matInput [formControl]="author" type="text" maxlength="255" />
                        <mat-error class="error-message" *ngIf="author.invalid">{{ formService.getErrorMessage(author) }}</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>{{ 'quiz-add.requiredTime' | translate }}</mat-label>
                        <input matInput [formControl]="duration" type="number" />
                        <mat-error class="error-message" *ngIf="duration.invalid">{{ formService.getErrorMessage(duration) }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <button [disabled]="!form.valid || isBusy" (click)="uploadFile()" class="primary">{{ 'quiz-add.add-button' | translate }}</button>
    </form>

</section>
