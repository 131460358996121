import { IQuestion } from './question';
import { IAnswer } from './answer';
export class AnsweredQuestion
{
    index:number;
    question: IQuestion;
    answer: IAnswer;
    next: AnsweredQuestion;
    previous: AnsweredQuestion;

    constructor(question: IQuestion) {
        this.question = question;
        this.next = null;
        this.previous = null;
    }
}
