import { Component, OnInit } from '@angular/core';
import { QuizService } from '../../services/quiz.service';
import { IQuiz } from '../../models/quiz';
import { IQuizResult } from '@quiz-lib';
import { RouteTools } from '@app/tools';
import { LocalizationService } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { AppModel } from '@app/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
    public loaded: boolean = false;
    public showResult: boolean = false;
    public quizId: string;
    public quiz: IQuiz;
    public results: IQuizResult[];
    private sortedByStrengths = true;

    constructor(private quizService: QuizService,
        route: ActivatedRoute,
        public localizationService: LocalizationService,
        public app: AppModel,
        private titleService: Title) {
        this.quizId = RouteTools.getId(route);
    }

    async ngOnInit() {
        this.quiz = await this.quizService.getQuiz(this.quizId);
        this.loaded = true;
        this.titleService.setTitle(this.quiz.title);
    }

    displayResults(results: IQuizResult[]) {
        this.results = results;
        this.sortByStrength();
        this.showResult = true;
    }

    sortByVigilanceZone() {
        this.results = this.results.sort((n1, n2) => {
            if (n1.value > n2.value) return 1;
            if (n1.value < n2.value) return -1;
            return n1.category.label.localeCompare(n2.category.label);
        });

        this.sortedByStrengths = false;
    }

    sortByStrength() {
        this.results = this.results.sort((n1, n2) => {
            if (n1.value < n2.value) return 1;
            if (n1.value > n2.value) return -1;
            return n1.category.label.localeCompare(n2.category.label);
        });

        this.sortedByStrengths = true;
    }

    get sortOrder(): string {
        return this.sortedByStrengths ? "↓" : "↑";
    }

    sort() {
        if (this.sortedByStrengths) {
            this.sortByVigilanceZone();
        } else {
            this.sortByStrength();
        }
    }
}
