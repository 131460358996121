import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/shared';
import { ToolCardComponent } from './components/tool-card/tool-card.component';
import { ToolBoxThemeViewComponent } from './components/tool-box-theme-view/tool-box-theme-view.component';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [
        ToolCardComponent,
        ToolBoxThemeViewComponent
        ],
  imports: [
      SharedModule,
      RouterModule
    ],
    exports: [
        ToolCardComponent,
        ToolBoxThemeViewComponent
        ]
})
export class ToolGroupModule { }
