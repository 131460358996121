import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { AppModel } from '@app/core/models/app.model';

import { Activity, Agent, IStatement, IStatementDefinition, IVerb } from '../models/statement';
import { HierarchyItem } from '../models/hierarchy';
import { ChallengeHierarchyService } from './challengeHierarchy.service';

@Injectable()
export class XApiService {
    private statement: IStatement;

    constructor(private readonly http: HttpClient,
        private readonly app: AppService,
        private readonly appModel: AppModel,
        private readonly challengeHierarchyService: ChallengeHierarchyService) { }

    public createFeelingStatement(feelingEmoji: string): XApiService {
        this.statement = {
            actor: new Agent(`mailto:${this.appModel.identity.email}`),
            verb: {
                id: `${this.app.baseUrl}verbs/felt`,
                display: {
                    'fr-CA': 'c\'est senti',
                    'en-CA': 'felt'
                }
            } as IVerb,
            object: new Activity(),
            result: {
                response: feelingEmoji
            }
        } as IStatement;

        return this;
    }

    public async setChallenge(challengeId: string): Promise<XApiService> {
        const hierarchy = await this.challengeHierarchyService.getChallengeHierarchy(challengeId);
        const objectItem = this.mapHierarchyItemToActivity(hierarchy);

        const statementActivity = this.statement.object as Activity;
        statementActivity.id = objectItem.id;
        statementActivity.definition = objectItem.definition;

        this.statement.context = {
            contextActivities: {
                parent: new Array<Activity>()
            }
        }

        this.setContextParent(hierarchy.parent);

        return this;
    }

    public send(): Promise<string> {
        if (!this.statement) {
            throw new Error('Cannot send an empty statement.');
        }

        return this.http.post<string>(`${this.app.baseUrl}xapi/statements`, this.statement).toPromise();
    }

    private setContextParent(parentItem: HierarchyItem) {
        const parent = this.mapHierarchyItemToActivity(parentItem);
        this.statement.context.contextActivities.parent.push(parent);

        if (parentItem.parent)
            this.setContextParent(parentItem.parent);
    }

    private mapHierarchyItemToActivity(item: HierarchyItem): Activity {
        const description = item.description || item.name;

        const id = `${this.app.baseUrl}${item.type}/${item.id}`;
        const definition: IStatementDefinition = {
            name: {
                'fr-CA': item.name,
                'en-CA': item.name
            },
            description: {
                'fr-CA': description,
                'en-CA': description
            }
        }

        return new Activity(id, definition);
    }
}
