import { Injectable     } from '@angular/core';
import { Location       } from '@angular/common';
import { Router, Route ,UrlTree } from '@angular/router';
import {HttpParams} from '@angular/common/http'
import { Page, Language } from '@app/global';
import { LanguageTools  } from '@app/tools';

@Injectable()
export class RoutingService
{
    constructor(private router:   Router,
                private location: Location) { }

    public getPath(page: Page, language: Language, id?: string, option?: string): string
    {
        // Find path to specified page from configured routes
        let path = this.findPath(this.router.config, page, '');
        if (!path)
            return '/';

        // Apply params
        path = path.replace(':language', language);
        if (id)
            path = path.replace(':id', id);
        if (option)
            path = path.replace(':option', option);

        return path;
    }
    
    public getPathToLanguage(language: Language): string
    {
        // Build route with new specified language
        var parts = this.splitUrl(this.location.path(true));
        parts[1] = language;
        return parts.join('/');
    }

    public getPathToPage(page: Page,id?:string): string
    {
        // Get language from current url
        let parts    = this.splitUrl(this.location.path(true));
        let language = LanguageTools.getLanguageFromCode(parts[1]);

        // Build route with new specified page
        return this.getPath(page,
                            language ? language : Language.Default,id);
    }

    private findPath(routes: Route[], page: Page, basePath: string): string
    {
        for (let route of routes)
        {
            let path = basePath;
            if (route.path && route.path.length > 0)
                path += '/' + route.path;

            if (route.data)
            {
                if (route.data['page'] === page)
                    return path;

                let lazyRoutes = route.data['routes'];
                if (lazyRoutes)
                {
                    let lazyPath = this.findPath(lazyRoutes, page, path);
                    if (lazyPath)
                        return lazyPath;
                }
            }

            if (route.children)
            {
                path = this.findPath(route.children, page, path);
                if (path)
                    return path;
            }
        }
        return null;
    }

    private splitUrl(url: string): string[]
    {
        // (normalize url)
        if (!url.startsWith('/'))
            url = '/' + url;

        return url.split('/');
    }

    public getPageUrlTree(page: Page,id?:string, params?: HttpParams): UrlTree{
      
        if(params){
            return this.router.parseUrl(this.getPathToPage(page,id)+"?"+ params.toString());
        }
       
        return this.router.parseUrl(this.getPathToPage(page,id));
    }
}
