import { Component, Input, OnInit } from '@angular/core';
import { IFeeling } from '../../models/feeling';
import { ICommitment } from '../../models/commitment';
import * as _ from 'lodash';
import { FeelingService } from '../../services/feeling.service';
import { XApiService } from '../../services/xApi.service';
import { EmoticonService, TranslateService } from '@app/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppModel } from '@app/core';

@Component({
    selector: 'retro-form',
    templateUrl: './retro-form.component.html',
    styleUrls: ['../../../shared/icons.shared.css', './retro-form.component.css', './retro-form.component.mobile.css'],

})

export class RetroFormComponent implements OnInit {

    public feelings: IFeeling[];
    @Input() commitment: ICommitment;
    @Input() displayCurrentValue: boolean;
    @Input() challengeId: string;
    @Input() challengeStarted: boolean = true;

    evaluationInternal: string = '';
    public loaded: boolean = false;
    constructor(private app: AppModel,
        private feelingService: FeelingService,
        private emoticonService: EmoticonService,
        private translateService: TranslateService,
        private xApiService: XApiService,
        private snackBar: MatSnackBar) {

    }

    async ngOnInit() {
        await this.translateService.load(this.app.language, "retro-form");
        this.feelings = await this.feelingService.getFeelings();
        this.loaded = true;

    }

    async ngOnChanges() {
        if (!this.commitment.feeling) {
            this.commitment.feeling = <IFeeling>{};
        }
        if (this.displayCurrentValue) {
            this.evaluationInternal = this.commitment.evaluation;
        }
    }

    getEmojiUrl(emoji: string) {
        return this.emoticonService.emojisUrl.get(emoji);
    }

    onFeelingChanged(feelingid) {
        let feeling = this.feelings.find(f => f.id === feelingid);

        this.commitment.feeling = _.cloneDeep(feeling);

    }

    save() {
        this.commitment.evaluation = this.evaluationInternal;
        this.feelingService.updateSelfEvaluation(this.commitment.publicId, this.commitment.feeling.id, this.commitment.evaluation)
            .then(() => {
                this.sendXApiStatement();
                this.snackBar.open(this.translateService.instant('retro-form.sendConfirmation'),
                    this.translateService.instant('retro-form.close'),
                    { duration: 3000 });
            });

        if (!this.displayCurrentValue) {
            this.evaluationInternal = '';
        }
    }

    private sendXApiStatement() {
        this.xApiService.createFeelingStatement(this.commitment.feeling.emoji).setChallenge(this.challengeId).then(statement => {
            statement.send();
        });
    }
}
