import { Injectable          } from '@angular/core';
import { FormControl         } from '@angular/forms';
import { CustomError         } from '../forms/validators';
import { LocalizationService } from './localization.service';

@Injectable()
export class FormService
{
    constructor(private localization: LocalizationService) { }

    public getErrorMessage(control: FormControl): string | null
    {
        // (return first error only)
        for (var key in control.errors)
            if (key === 'custom')
                return (control.errors[key] as CustomError)();
            else
                return this.localization.getString('form.errors.' + key);

        return null;
    }
}
