import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'quick-feedback',
    templateUrl: './quick-feedback.component.html',
    styleUrls: ['./quick-feedback.component.css'],
})

export class QuickFeedbackComponent {
    @Input() public challengeId: string;

    constructor() {}
}
