import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/shared';

import { ChallengeNavComponent } from './components/challenge-nav/challenge-nav.component';
import { RouterModule } from '@angular/router';
import { ChallengeNavService } from './services/challenge-nav.service';
import { NavigationModule } from '../navigation';


@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        NavigationModule
    ],
    declarations: [
        ChallengeNavComponent
    ],
    exports: [
        ChallengeNavComponent
   ],
    providers: [ChallengeNavService],
})

export class ChallengeNavModule { }
