import { Component, OnInit, Input } from '@angular/core';
import { ITool } from '../../models/tool';
import { AppModel } from '@app/core';
@Component({
  selector: 'app-tool-view',
  templateUrl: './tool-view.component.html',
    styleUrls: [
        './tool-view.component.css',
        './tool-view.component.mobile.css',
        '../../shared/shared.css'
    ]
})
export class ToolViewComponent implements OnInit {

    @Input() tool: ITool;
    @Input() themes: string[];
    constructor(public app: AppModel) { }

   ngOnInit() {
   }

}
