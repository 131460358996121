<div *ngIf="challengeId" class="flyout display-phone" [class.opened-flyout]="openedFlyout" [class.closed-flyout]="!openedFlyout">
    <quick-feedback [challengeId]="challengeId"></quick-feedback>
</div>

<div class="bottom-nav display-phone">
    <a [class.resource-loaded]="resourceLoaded" (click)="goToBoard()" [class.hidden-menu]="openedFlyout" [class.active]="isBoardActive()">{{'nav.dashboard' | translate}}</a>
    <a [class.resource-loaded]="resourceLoaded" (click)="goToFeed()" [class.hidden-menu]="openedFlyout" [class.active]="isFeedActive()">{{'nav.new' | translate}}</a>
    <a [class.resource-loaded]="resourceLoaded" class="main-action" *ngIf="!displaySave() && challengeId" (click)="toggleFlyout()">
        <ng-container *ngIf="!openedFlyout; then displayThumbsUp else displayCancel"></ng-container>
    </a>
    <a *ngIf="resourceLoaded" [class.resource-loaded]="resourceLoaded" [class.pulsingButton]="helpService.showContributionHint" (click)="goToContribution()" [class.hidden-menu]="openedFlyout" >{{'tonic.nav.myContribution' | translate}}</a>
    <a *ngIf="resourceLoaded" [class.resource-loaded]="resourceLoaded" [attr.disabled]="isContentButtonDisabled" [attr.href]="plan.journeyUrl" [class.hidden-menu]="openedFlyout" target="_blank">{{'tonic.nav.content' | translate}}</a>
</div>

<div *ngIf="displaySave()" class="bottom-nav save-nav display-phone" [class.flattened]="openedFlyout">
    <a (click)="save()" class="active" [class.hidden-menu]="openedFlyout">{{'dashboard.myContribution.save' | translate}}</a>
    <a (click)="cancel()" [class.hidden-menu]="openedFlyout">{{'dashboard.myContribution.cancel' | translate}}</a>
</div>


<ng-template #displayThumbsUp>
    <mat-icon class="material-icons-outlined">thumb_up_alt</mat-icon>
</ng-template>

<ng-template #displayCancel>
    <mat-icon class="material-icons-outlined">clear</mat-icon>
</ng-template>
