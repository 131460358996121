import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IQuestionCategory } from '../../models/question.category';
import { ILocalizationService } from '../../services/localization.service';

@Component({
    selector:    'result-lib',
    templateUrl: './result-lib.component.html',
    styleUrls: ['./result-lib.component.css',
        '../shared/theme.teamtonic.css',
        './result-lib.component.teamtonic.css']
})
export class ResultLibComponent implements  OnChanges
{
    @Input() public category: IQuestionCategory;
    @Input() public value: number;
    @Input() public localization: ILocalizationService;
    @Input() public language: string;

    ngOnChanges(): void {
        this.value = Math.max(this.value, this.min);
        this.value = Math.min(this.value, this.max);
    }

    get label(): string {
        return this.category.results.find(r => this.value >= r.low && this.value <= r.high).label;
    }

    get max(): number {
        return Math.max(...this.category.results.map(r => r.high));
    }

    get min(): number {
        return Math.min(...this.category.results.map(r => r.low));
    }
}
