import { Language } from '@app/global';

export class LanguageTools
{
    public static getLanguageFromCode(code: string): Language | null
    {
        if (code)
            switch (code.toLowerCase())
            {
                case 'fr': return Language.French;
                case 'en': return Language.English;
            }

        return null;
    }
}
