import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { ITool } from '../../models/tool';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { RoutingService, AppModel } from '@app/core';
import { Page } from '@app/global';
import { ToolType } from '../../models/tool.type';
@Component({
  selector: 'app-tool-list',
  templateUrl: './tool-list.component.html',
    styleUrls: ['../../../shared/admin.shared.css','./tool-list.component.css']
})
export class ToolListComponent implements OnInit, OnChanges {

    @ViewChild(MatSort) sort: MatSort;
    dataSource = new MatTableDataSource<ITool>();

    @Input() selectable: boolean = false;
    @Input() tools: ITool[];
    selection: SelectionModel<ITool> = new SelectionModel<ITool>(true, []);

    @Output() selectedChange = new EventEmitter();
    @Output() itemSuppressed = new EventEmitter<ITool>();

    @Input()
    get selected() {
        return this.selection;
    }

    constructor(private routeService: RoutingService,
        private appModel: AppModel) {

    }


    set selected(val) {
        this.selection = val;
        this.selectedChange.emit(this.selection);
    }

    ngOnChanges(): void {
    this.dataSource.data = this.tools;
    this.dataSource.sort = this.sort;
    }

    get displayedColumns(): string[] {
        if (this.selectable)
            return ['select','image', 'title', 'duration', 'author','actions'];

        return ['image', 'title', 'duration', 'author', 'actions'];
    }

    toolViewUrl(tool: ITool) {

        return this.routeService.getPath(tool.type === ToolType.Document ? Page.Document : Page.Quiz, this.appModel.language,tool.id);
    }

    ngOnInit() {
        this.dataSource.data = this.tools;
        this.dataSource.sort = this.sort;
    }

    public onItemSuppressed(tool: ITool) {
        this.itemSuppressed.emit(tool);
    }
}
