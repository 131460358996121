import { Type  } from '@angular/core';
import { Route } from '@angular/router';
import { Page  } from '@app/global';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function PlansRoutes(plansComponent?: Type<any>, planComponent?: Type<any>): Route[] {
    return [
        {
            path: '',
            data: { i18n: 'plans' },
            children: [
                { path: '',    component: plansComponent, data: { page: Page.Plans } },
                { path: ':id', component: planComponent, data: { page: Page.Plan } }
            ]
        }
    ];
}
