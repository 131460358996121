import { Type  } from '@angular/core';
import { Route } from '@angular/router';

// *** IMPORTANT: Never import anything from the module itself because this would establish a strong reference to inner components and break the lazy-loading (if any) ***

export function NavigationRoutes(mainMenuComponent?: Type<any>): Route[] {
    return [
        {
            path: '',
            data: { i18n: 'navigation' },
            children: [
                { path: '', component: mainMenuComponent }
            ]
        }
    ];
}
