<div *ngIf="dataSource.data" class="side-nav">
    <div class="switches" *ngIf="appService.isMobile()">
        <div class="personal">
            <session-switch (click)="close()"></session-switch>
            <language-switch></language-switch>
        </div>

        <hr />

        <div class="sub-menu">
            <div>
                <a routerLink="{{FAQUrl}}" (click)="close()" target="_blank">FAQ</a>
                <mat-icon>open_in_new</mat-icon>
            </div>
        </div>

        <hr />

        <div class="sub-menu">
            <div>
                {{ 'footer.supportText' | translate }}&nbsp;<a href="mailto:support@teamtonic.com">{{ 'footer.contactText' | translate }}</a>
            </div>
        </div>

        <hr />
    </div>
    
    <h4>{{'navigation.header' | translate}}</h4>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: isNodeChallenge" matTreeNodeToggle>
            <li class="challenge" [class.current]="node.id === currentChallengeId">
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button disabled></button>
                <mat-icon>arrow_forward</mat-icon>
                <a routerLink="{{dashboardUrl}}" [queryParams]="{ challengeId: node.id }" (click)="close()">{{node.name}}</a>
            </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: isNodeTeam">
            <li class="team">
                <div class="mat-tree-node">
                    <button *ngIf="node.children" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon>
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <p class="team-name">
                        <span class="icon-container"><mat-icon>people</mat-icon></span> <span>{{node.name}}</span>
                    </p>
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: isNodePlan">
            <li class="plan">
                <div class="mat-tree-node">
                    <button *ngIf="node.children" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon>
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <p class="plan-name">
                        <small>{{'dashboard.planName'  | translate}}</small>
                        <span class="icon-container"></span> <span>{{node.name}}</span>
                    </p>
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: isNodeOrganization">
            <li class="organization">
                <div class="mat-tree-node">
                    <button *ngIf="node.children" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon>
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <p class="organization-name">
                        <span class="icon-container"><mat-icon>business</mat-icon></span> <span class="name">{{node.name}}</span>
                    </p>
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node;">
            <li class="expandable-node">
                <div class="mat-tree-node">
                    <button *ngIf="node.children.length > 0" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon>
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    {{node.name}}
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>

    </mat-tree>

</div>
