<section *ngIf="app.identity">
    <h1>{{ 'toolbox-admin.headers.tools' | translate }}</h1>

    <div class="columns">
        <div class="items">
            <h2>{{ 'toolbox-admin.headers.quiz' | translate }}</h2>
            <div class="actions">
                <button class="add secondary" (click)="openAddQuizDialog()">
                    <mat-icon>add</mat-icon>{{ 'toolbox-admin.add.quiz' | translate }}
                </button>
            </div>

            <app-tool-list [selectable]="true"
                           [(selected)]="selectedQuizzes"
                           [tools]="quizzes"
                           (itemSuppressed)="deleteQuiz($event)"></app-tool-list>
            
            <h2>{{ 'toolbox-admin.headers.documents' | translate }}</h2>
            <div class="actions">
                <button class="add secondary" (click)="openAddDocumentDialog()">
                    <mat-icon>add</mat-icon>{{ 'toolbox-admin.add.document' | translate }}
                </button>
            </div>

            <app-tool-list [selectable]="true"
                           [(selected)]="selectedDocuments"
                           [tools]="documents"
                           (itemSuppressed)="deleteDocument($event)"></app-tool-list>

            
        </div>

        <div class="boxes">
            <h2>{{ 'toolbox-admin.toolboxTitle' | translate }}</h2>

            <div *ngFor="let box of toolboxes" class="tool-container">
                <span class="theme">{{box.theme.name}}</span>
                <div class="actions">
                    <button class="add secondary" (click)="addSelectedTools(box.id)">
                        <mat-icon>add</mat-icon>{{ 'toolbox-admin.add.tool' | translate }}
                    </button>
                </div>
                <app-tool-list
                    [tools]="box.tools"
                    (itemSuppressed)="removeFromToolBox($event,box.id)"
                ></app-tool-list>
            </div>
        </div>
    </div>

</section>
