import { Injectable } from '@angular/core';

import { ISavable } from '../models/savable.model';
@Injectable()
export class SaveBarService {
    public handler: ISavable;
    constructor() {
   }

   public show(handler: ISavable) {
       this.handler = handler;
   }

   public hide() {
      this.handler = null;
   }
}
