import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NotificationService, LocalizationService } from '@app/core';
import { FeedbackService } from '../../services/feedback.service';
import { IFeedback } from '../../models/feedback';
import { AppModel } from "@app/core/models/app.model";
import { IPerson } from '../../models/person';

@Component({
    selector: 'feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['../../../shared/icons.shared.css', './feedback-form.component.css', './feedback-form.component.mobile.css'],
})

export class FeedbackFormComponent {
    @Output() feedbackSent = new EventEmitter<IFeedback>();
    comment: string = "";
    @Input() sender: IPerson;
    @Input() recipient: IPerson;

    @Input() disabled:boolean;
    @ViewChild("feedbackForm") feedbackFormField: ElementRef;
    @Input() challengeId: string;
    @Input() commitmentId: string;

    constructor(private notification: NotificationService,
       private localization: LocalizationService,
       private feedbackService: FeedbackService,
       private app: AppModel) { }

    async  send() {
        if (this.comment.trim().length > 0) {
         let feedback = await this.feedbackService.addFeedback(this.challengeId, this.commitmentId, this.comment);
         feedback.canEdit = true;
          this.feedbackSent.emit(feedback);
          this.comment = "";
      } else {
            this.notification.showError(this.localization.getString('dashboard.feedbackError.message'),
                this.localization.getString('dashboard.feedbackError.action'));
        }
    }

    displayPlaceholder(): string {
       if (this.sender) {
            return this.self
               ? `${this.recipient.firstName}${this.localization.getString('dashboard.feedback.self.placeholder')}`
               : `${this.localization.getString('dashboard.feedback.placeholder')} ${this.recipient.firstName}`;
        } else {
            return this.localization.getString('dashboard.feedback.placeholderAdmin');
        }
        
   }

   get self(): boolean {

       return this.sender === this.recipient;
   }

}
