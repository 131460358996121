import { Component                                    } from '@angular/core';
import { AppModel, IdentityService, NavigationService } from '@app/core';
import { Page                                         } from '@app/global';

@Component
({
    selector:    'session-switch',
    templateUrl: './session-switch.component.html',
    styleUrls: ['./session-switch.component.css', './session-switch.component.mobile.css']
})
export class SessionSwitchComponent
{
    constructor(public  app:        AppModel,
                private identity:   IdentityService,
                private navigation: NavigationService) { }

    public login()
    {
        this.navigation.navigateToPage(Page.Login);
    }

    public async logout()
    {
        await this.identity.logout();

        this.navigation.navigateToPage(Page.Login);
    }
}
