import { NgModule     } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/modules/shared';
import { QuickFeedbackModule } from '@app/modules/quick-feedback';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { SessionSwitchComponent  } from './components/session-switch/session-switch.component';
import { MainMenuComponent       } from './components/main-menu/main-menu.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationMobileService } from './services/navigation.mobile.service';
import { EmptyMenuComponent } from './components/empty-menu/empty-menu.component';
import { PlanService } from './services/plan.service';
@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([]),
        QuickFeedbackModule
    ],
    declarations: [
        LanguageSwitchComponent,
        SessionSwitchComponent,
        MainMenuComponent,
        MobileMenuComponent,
        EmptyMenuComponent,
        FooterComponent
    ],
    exports: [
        MainMenuComponent,
        SessionSwitchComponent,
        LanguageSwitchComponent,
        MobileMenuComponent,
        EmptyMenuComponent,
        FooterComponent
   ],
   providers: [
        NavigationMobileService,
        PlanService
   ]
})
export class NavigationModule { }
