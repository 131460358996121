import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService, AppModel } from '@app/core';
import { IPlan } from "../models/plan";
import { Language } from '@app/global';
import { IToolBox } from "../models/toolbox";
import { ITool } from "../models/tool";
import { ToolType } from "../models/tool.type";

@Injectable()
export class ToolBoxService
{

    constructor(private http: HttpClient,
        private app: AppService,
        private appModel: AppModel) { }

    public sendContent(planId: string, themeId:string,url:string): Promise<string>
    {
        return this.http.post<string>(`${this.app.baseUrl}api/plan/${planId}/content/send`, { themeId, url}).toPromise();
    }


    public getPlan(planId: string, language: Language): Promise<IPlan> {
        return this.http.get<IPlan>(`${this.app.baseUrl}api/plan/${planId}?language=${language}`).toPromise();
    }


    public getUserToolBoxes(): Promise<IToolBox[]> {
        return this.http.get<IToolBox[]>(`${this.app.baseUrl}api/toolbox/get`, {
            params: {
                language: this.appModel.language
            }
        }).toPromise();
    }

    public getAllToolBoxes(): Promise<IToolBox[]> {
        return this.http.get<IToolBox[]>(`${this.app.baseUrl}api/toolbox/getAll`, {
            params: {
                language: this.appModel.language
            }
        }).toPromise();
    }

    public addTools(toolboxId: string, tools: ITool[]): Promise<void> {
        return this.http.post<void>(`${this.app.baseUrl}api/toolbox/add`, {
            toolboxId,
            tools
        }).toPromise();
    }

    public remove(input: ITool, toolboxId: string): Promise<void> {
    
        return this.http
            .post<void>(`${this.app.baseUrl}api/toolbox/remove`, input, {
                params: {
                    toolboxId
                }
            }
        ).toPromise();
    }
}
