import { Component, Input    } from '@angular/core';
import { AppModel            } from '@app/core';
import { LocalizationService } from 'app/core';
import { TimeService         } from 'app/core';
import * as moment from 'moment';

@Component
({
    selector:    'time-ago',
    templateUrl: './time-ago.component.html',
    styleUrls: ['./time-ago.component.css'],
})
export class TimeAgoComponent
{
    @Input() date: Date;

    constructor(public app: AppModel,
        public time: TimeService,
        private localization: LocalizationService) {
       
       moment.updateLocale(this.app.language, {});
    }

    public getText(from: Date): string
    {
        if (!this.date)
            return null;

       return moment(this.date).fromNow();
    }
}
