import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService, AppModel } from '@app/core';
import {ITeam} from '../models/team';
import {IPlan} from '../models/plan';

@Injectable()
export class PlanService {

  constructor(private http: HttpClient,
    private app: AppService,
    private appModel: AppModel) {}

    public getPlan(challengeId: string): Promise<IPlan> {
      let language = this.appModel.language;
      return this.http.get<ITeam>(`${this.app.baseUrl}api/dashboard/Team/Challenge`, {
          params: {
              challengeId,
              language
          }
      }).toPromise().then(team => team.plan);
   }
}
