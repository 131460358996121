import { Component, Input} from '@angular/core';
import { ChallengeStatus } from '../../models/challenge';

@Component({
    selector:    'challenge-status-tag',
    templateUrl: './challenge-status-tag.component.html',
    styleUrls: ['./challenge-status-tag.component.css']
})

export class ChallengeStatusTagComponent
{
    @Input() status: ChallengeStatus;
}
