import { Route } from '@angular/router';
import { Type } from '@angular/core';
import { DefaultPageGuard, LanguageGuard, AppModelGuard } from '@app/core';

import { MainMenuComponent, MobileMenuComponent, FooterComponent } from '@app/modules/navigation';
import { ChallengeNavComponent } from '@app/modules/challenge-nav';

import { ChallengesRoutes    } from '@app/modules/challenges';
import { DashboardRoutes } from '@app/modules/dashboard';
import { LoginRoutes         } from '@app/modules/login';
import { OrganisationsRoutes } from '@app/modules/organisations';
import { OrganizationRoutes } from '@app/modules/organization';
import { NavigationRoutes    } from '@app/modules/navigation';
import { PasswordRoutes      } from '@app/modules/password';
import { PlansRoutes         } from '@app/modules/plans';
import { SignupRoutes        } from '@app/modules/signup';
import { ToolBoxRoutes } from '@app/modules/toolbox';
import { TeamsRoutes } from '@app/modules/teams';
import { ViewFeedRoutes } from '@app/modules/view-feed';
import { MyContributionRoutes } from '@app/modules/my-contribution';
import { AssistanceRoutes } from '@app/modules/assistance';
import { OnBoardingRoutes } from '@app/modules/onboarding';

export const AppRoutes: Route[] = [
   {
      path: '',
      canActivate: [AppModelGuard],
      children: [
          {
                path: ':language',
                canActivateChild: [LanguageGuard],
                children: [
                    {
                      path: '',
                      children: [
                          {
                              path: 'teams',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/teams/teams.module').then(m => m.TeamsModule),
                                      data: { routes: TeamsRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          {
                              path: 'challenges',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/challenges/challenges.module').then(m => m.ChallengesModule),
                                      data: { routes: ChallengesRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          {
                              path: 'dashboard',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
                                      data: { routes: DashboardRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          {
                              path: 'view-feed',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/view-feed/view-feed.module').then(m => m.ViewFeedModule),
                                      data: { routes: ViewFeedRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          {
                              path: 'my-contribution',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/my-contribution/my-contribution.module').then(m => m.MyContributionModule),
                                      data: { routes: MyContributionRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          { path: 'login', loadChildren: () => import('@app/modules/login/login.module').then(m => m.LoginModule), data: { routes: LoginRoutes() } },
                          {
                              path: 'organisations',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/organisations/organisations.module').then(m => m.OrganisationsModule),
                                      data: { routes: OrganisationsRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          {
                              path: 'organization-landing',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/organization/organization.module').then(m => m.OrganizationModule),
                                      data: { routes: OrganizationRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          { path: 'password', loadChildren: () => import('@app/modules/password/password.module').then(m => m.PasswordModule), data: { routes: PasswordRoutes() } },
                          {
                              path: 'plans',
                              children: [
                                  {
                                      path: '',
                                      loadChildren: () => import('@app/modules/plans/plans.module').then(m => m.PlansModule),
                                      data: { routes: PlansRoutes() }
                                  },
                                  {
                                      path: '',
                                      outlet: 'navigation',
                                      component: MainMenuComponent
                                  }
                              ]
                          },
                          { path: 'signup', loadChildren: () => import('@app/modules/signup/signup.module').then(m => m.SignupModule), data: { routes: SignupRoutes() } },
                          { path: 'toolbox', loadChildren: () => import('@app/modules/toolbox/toolbox.module').then(m => m.ToolBoxModule), data: { routes: ToolBoxRoutes() } },
                          { path: 'assistance', loadChildren: () => import('@app/modules/assistance/assistance.module').then(m => m.AssistanceModule), data: { routes: AssistanceRoutes() } },
                          { path: 'onboarding', loadChildren: () => import('@app/modules/onboarding/onboarding.module').then(m => m.OnBoardingModule), data: { routes: OnBoardingRoutes() } },
                          { path: '**', canActivate: [DefaultPageGuard], redirectTo: '' }
                      ]
                    },
                    {
                        path: '',
                        outlet: 'navigation-mobile-bottom',
                        children: NavigationRoutes(MobileMenuComponent)
                    },
                    {
                        path: '',
                        outlet: 'navigation-mobile-side-right',
                        children: NavigationRoutes(ChallengeNavComponent)
                    },
                    {
                        path: '',
                        outlet: 'footer',
                        component: FooterComponent
                    }
                ]
            },
            {
                path: '**',
                canActivate: [DefaultPageGuard],
                redirectTo: ''
            }
      ]
   }
];
