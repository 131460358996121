<div>
    <textarea [disabled]="disabled"
              [(ngModel)]='comment'
              #feedbackForm maxlength="140"
              (keydown.enter)= "send()"
              autosize
              placeholder="{{displayPlaceholder()}}"></textarea>
    <button
        [disabled]="disabled"
        class="icon-btn"
        (click)="send()">
        <mat-icon>send</mat-icon>
    </button>
</div>
