import { Injectable } from '@angular/core';
import twemoji from '@twemoji/api';
// This class (and associated pipe) is designed to be a enhanced version of ngx-translate, which
// must be preloaded before page rendering. This can be easily done by using a global route guard.

@Injectable()
export class EmoticonService
{
    public emojisUrl: Map<string, string>;

    getEmojiUrl(emoji: string) {
        var img = twemoji.parse(emoji);

        var regex = /<img.*?src="(.*?)"/;
        var url = regex.exec(img)[1];

        return url;
    }

    load(emojis: string[]) {
        this.emojisUrl = new Map<string, string>();

        emojis.forEach(e => {
            this.emojisUrl.set(e, this.getEmojiUrl(e));
        });
    }

}
