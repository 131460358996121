<div>
    <h3>{{title}}</h3>

    <mat-grid-list cols="3" gutterSize="12px"  rowHeight="34px" class="grid-list" style="min-width: 175px">
        <mat-grid-tile class="feeling-item" *ngFor="let value of values; trackBy: feelingId" title="{{value.feeling.name}}">
            <div class="background" [class.zero]="value.count == 0" [ngStyle]="{'opacity': getOpacity(value.count)}"></div>
            <div class="content" [class.zero]="value.count == 0">
                <img class="emoji" src="{{ getEmojiUrl(value.feeling.emoji) }}" /> <span id="feelingCount" class="value">{{value.count}}</span>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
