import { Component, OnInit } from '@angular/core';
import { ToolBoxService } from '../../services/toolbox.service';
import { IToolBox } from '../../models/toolbox';
import { ITheme } from '../../models/theme';
import { AppModel, TranslateService } from '@app/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-toolbox-view',
    templateUrl: './toolbox-view.component.html',
    styleUrls: ['./toolbox-view.component.css', './toolbox-view.component.mobile.css', '../../shared/shared.css']
})
export class ToolboxViewComponent implements OnInit {

    public toolboxes: IToolBox[] = [];
    public selectedTheme: ITheme;
    constructor(private toolboxService: ToolBoxService,
        public app: AppModel,
        translateService: TranslateService,
        titleService: Title) {
        titleService.setTitle(translateService.instant('toolbox.title'));
    }

    async ngOnInit() {
        this.toolboxes = await this.toolboxService.getUserToolBoxes();
    }

    get themes(): ITheme[] {
        return this.toolboxes.map(t => t.theme);
    }

    get displayedToolboxes(): IToolBox[] {
        if (this.selectedTheme) {
            return this.toolboxes.filter(t => t.theme === this.selectedTheme);
        }

        return this.toolboxes;
    }
}
