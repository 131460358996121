<a routerLink="{{toolUrl}}" target="_blank">
    <mat-card>
        <div class="heading">
            <div class="image" [style.backgroundImage]="imageBackground"></div>
            <p class="title">{{tool.title}}</p>
        </div>
        
        <div class="details">
            <div class="type">
                <ng-container *ngIf="tool.type === types.Document;else quiz">
                    <mat-icon>description</mat-icon>
                </ng-container>
                <ng-template #quiz>
                    <ng-container *ngIf="tool.type === types.Quiz;else unknown">
                        <mat-icon>poll</mat-icon>
                    </ng-container>
                </ng-template>
                <ng-template #unknown></ng-template>
            </div>
            <div class="duration">
                {{ 'tool-card.duration' | translate }} {{tool.durationInMinutes}} min
            </div>
        </div>
    </mat-card>
</a>
