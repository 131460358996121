<section class="header">
    <h1>{{tool.title}}</h1>

    <div class="doc-info">
        <mat-card>

            <div>
                <label>{{ 'tool.duration' | translate }}</label>
                <p>{{tool.durationInMinutes}}  min</p>
            </div>
            <div>
                <label>{{ 'tool.published' | translate }}</label>
                <p>{{tool.publication | date : 'longDate' :'': app.language}}</p>
            </div>
            <div>
                <label>{{ 'tool.author' | translate }}</label>
                <p>{{tool.author}}</p>
            </div>
        </mat-card>

        <ul class="themes">
            <li *ngFor="let t of tool.themes" class="theme">{{t}}</li>
        </ul>
    </div>

    
    
</section>


<ng-content></ng-content>

