<div class="content-container">

    <div class="label">
        <div class="title-container">
            <img class="category-image category-id-{{category.id}}-image" />
            <h3 class="result-title category-id-{{category.id}}-title {{language}}">{{category.label}} </h3>
        </div>

        <p>{{label}}</p>
        
    </div>

    <div class="gauge">
        <linear-gauge-lib
            [localization]="localization"
            [max]="max"
            [value]="value">
        </linear-gauge-lib>
    </div>

</div>

