import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService, AppModel } from '@app/core';
import { IFeedback } from "../models/feedback";



@Injectable()
export class FeedbackService {
   constructor(private http: HttpClient,
      private app: AppService) {
   }

   public addFeedback(challengeId: string, commitmentId: string, comment: string): Promise<IFeedback> {
      let feedbackInput = {
         CommitmentId: commitmentId,
         comment: comment.trim(),
         challengeId: challengeId
      };

      return this.http.post<IFeedback>(`${this.app.baseUrl}api/feedback/Add`, feedbackInput).toPromise();
   }

   public updateFeedback(feedbackId: string, commitmentId: string, challengeId: string, comment: string): Promise<any> {
      let feedbackUpdateInput = { feedbackId: feedbackId, CommitmentId: commitmentId, challengeId: challengeId, comment: comment.trim() };
      return this.http.post<any>(`${this.app.baseUrl}api/feedback/Update`, feedbackUpdateInput).toPromise();
   }

   public deleteFeedback(feedback: string, commitmentId: string, challengeId: string): Promise<any> {

      let feedbackDeleteInput = { CommitmentId: commitmentId, FeedbackId: feedback, challengeId: challengeId }
      return this.http.post<any>(`${this.app.baseUrl}api/feedback/Delete`, feedbackDeleteInput).toPromise();
   }


}
