import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService    } from '@app/core';

@Pipe
({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform
{
    constructor(private translate: TranslateService) { }

    transform(value: string, data: any = undefined): string
    {
        return this.translate.instant(value, data);
    }
}
