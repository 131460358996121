import { NgModule } from '@angular/core';
import { QuizLibComponent } from './components/quiz-lib/quiz-lib.component';
import { ResultLibComponent } from './components/result-lib/result-lib.component';
import { LinearGaugeLibComponent } from './components/linear-gauge-lib/linear-gauge-lib.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material';
import { MaterialServicesModule } from './material';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QuizWelcomeComponent } from './components/quiz-welcome/quiz-welcome.component';

@NgModule({
    declarations: [QuizLibComponent, ResultLibComponent, LinearGaugeLibComponent, QuizWelcomeComponent],
    imports: [CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        MaterialServicesModule],
    exports: [QuizLibComponent, ResultLibComponent, QuizWelcomeComponent]
})
export class QuizLibModule { }
