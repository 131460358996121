import {
    Component,
    Input,
  
} from '@angular/core';
import { ILocalizationService } from '../../services/localization.service';

@Component({
    selector: 'linear-gauge-lib',
    templateUrl: './linear-gauge-lib.component.html',
    styleUrls: ['./linear-gauge-lib.component.css']
})
export class LinearGaugeLibComponent {
    private barLength: number = 240;
    @Input() max: number;
    @Input() value: number;
    @Input() public localization: ILocalizationService;

    get transformGaugeScale() {
        let ratio: number = (this.value / this.max) * this.barLength || 0;
        let invert = -1;
     
        return `translate(0, ${ratio * invert})`;
    }
}
