import { NgModule     } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreModule } from '@app/core';

import { NavigationModule } from '@app/modules/navigation';
import { SharedModule     } from '@app/modules/shared';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { QuickFeedbackModule } from './modules/quick-feedback';
import { ChallengeNavModule } from './modules/challenge-nav';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { environment } from '@env/environment';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        NavigationModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        QuickFeedbackModule,
        ChallengeNavModule,
        NgxGoogleAnalyticsModule.forRoot(environment.google.googleAnalyticsTrackingId),
        NgxGoogleAnalyticsRouterModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
