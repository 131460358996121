import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/core/services/app.service';
import { HierarchyItem } from '../models/hierarchy';

@Injectable()
export class ChallengeHierarchyService {
    constructor(private readonly http: HttpClient,
        private readonly app: AppService) { }

    public getChallengeHierarchy(challengeId: string): Promise<HierarchyItem> {
        return this.http.get<HierarchyItem>(`${this.app.baseUrl}api/challenges/GetChallengeHierarchy`, { params: { challengeId } }).toPromise();
    }
}
