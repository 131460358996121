import { Component, OnInit, Input } from '@angular/core';
import { ITool } from '../../models/tool';
import { ITheme } from '../../models/theme';

@Component({
  selector: 'app-tool-box-theme-view',
  templateUrl: './tool-box-theme-view.component.html',
    styleUrls: ['./tool-box-theme-view.component.css', './tool-box-theme-view.component.mobile.css']
})
export class ToolBoxThemeViewComponent implements OnInit {
    @Input() theme: ITheme;
    @Input() tools: ITool[];
    constructor() { }

     ngOnInit() {
    }

}
