import { Injectable        } from '@angular/core';
import { HttpClient        } from '@angular/common/http';
import { Language          } from '@app/global';
import { AppService        } from './app.service';
import { NavigationService } from './navigation.service';
import { AppModel          } from '../models/app.model';
import { IIdentityInfo     } from '../models/identity/identity-info';
import { ILoginResult      } from '../models/identity/login-result';
import { LoginStatus       } from '../models/identity/login-status';

@Injectable()
export class IdentityService
{
    constructor(private http:       HttpClient,
                private app:        AppService,
                private model:      AppModel,
                private navigation: NavigationService) { }

    public getInfo(): Promise<IIdentityInfo>
    {
        // note: server flag is used to bypass the SSR caching provided by ServerTransferStateModule
        // (ie, allow api to be called again from client-side rendering)
        return this.http.get<IIdentityInfo>(`${this.app.baseUrl}api/identity/info?server=${this.app.isPlatformServer}`).toPromise();
    }

    public async login(username: string, password: string): Promise<LoginStatus>
    {
        let result = await this.http.post<ILoginResult>(`${this.app.baseUrl}api/identity/login`,
        {
            username,
            password
        })
        .toPromise();

        if (result.status === LoginStatus.Success)
            this.model.identity = result.identity;

        return result.status;
    }

    public async logout(): Promise<void>
    {
        await this.http.post(`${this.app.baseUrl}api/identity/logout`, {}).toPromise();

        this.model.identity = await this.getInfo(); // (making a call after logout will refresh antiforgery token with new session state)
    }

    public signIn(scheme: string, language: Language, redirect: string = null)
    {
        if (redirect == null)
            redirect = this.navigation.returnUrl;

        if (redirect == null || redirect.length == 0)
            redirect = "/" + language;

        window.open(`${this.app.baseUrl}api/identity/login/${scheme}?redirect=${encodeURIComponent(redirect)}`, "_self");
    }
}
