<ng-container *ngIf="periods">
    <ng-container *ngIf="hasResponses; then valueState; else emptyState">

    </ng-container>
</ng-container>

<ng-template #valueState>
    <div class="outer-container">
        <div>
            <div class="canvas-container">
                <canvas baseChart style="max-width: 245px; width: 100%; min-width: 100%; height: 130px"
                        [datasets]="lineChartData"
                        [labels]="lineChartLabels"
                        [options]="lineChartOptions"
                        [colors]="lineChartColors"
                        [chartType]="lineChartType"></canvas>
            </div>
            <div *ngIf="!showChartOnly" class="hint-container">
                <p class="qualitative-value">{{getQualitativeValue()}}</p>
                <p *ngIf="showQualitativeDescription">{{getQualitativeDescription()}}</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #emptyState>
    <div class="empty-graph">
        <p>{{'dashboard.evaluation.empty' | translate}}</p>
    </div>
</ng-template>