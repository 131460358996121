import { Component, Input } from '@angular/core';
import { IPerson } from "../../../dashboard/models/person";

@Component
({
    selector: 'profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.css'],
})

export class ProfilePictureComponent
{
    @Input() person: IPerson;

    constructor() {
    }
}