import { NgModule            } from '@angular/core';
import { CommonModule        } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material';
import { FormsModule } from '@angular/forms';

import { TimeAgoComponent } from './components/time-ago/time-ago.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { RetroFormComponent } from './components/retro-form/retro-form.component';

import { FeelingCountComponent } from './components/feeling-count/feeling-count.component';
import { ChallengeStatusTagComponent } from './components/challenge-status-tag/challenge-status-tag.component';

import { FeelingService } from './services/feeling.service';
import { TonicTranslateService } from './services/tonic.translate.service';
import { ChallengeObserver } from './services/challenge.observer';
import { ChallengeHierarchyService } from './services/challengeHierarchy.service';
import { XApiService } from './services/xApi.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { AutosizeModule } from 'ngx-autosize';
import { ConfidenceChartComponent } from './components/confidence-chart/confidence-chart.component';
import { ChartsModule } from 'ng2-charts';
import { FileValueAccessor } from './directives/file.directive';
import { FileValidator } from './directives/required-file.directive';
import { SelfEvaluationInputComponent } from './components/self-evaluation-input/self-evaluation-input.component';
import { OnBoardingService } from './services/onboarding.service'
import { ParticipantService } from './services/participant.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        AutosizeModule,
        ChartsModule
    ],
    declarations: [
        TimeAgoComponent,
        ProfilePictureComponent,
        RetroFormComponent,
        TranslatePipe,
        FeelingCountComponent,
        ChallengeStatusTagComponent,
        ConfidenceChartComponent,
        FileValueAccessor,
        FileValidator,
        SelfEvaluationInputComponent
   ],
    providers: [FeelingService, TonicTranslateService, ChallengeObserver, OnBoardingService, XApiService, ChallengeHierarchyService, ParticipantService],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        TimeAgoComponent,
        ProfilePictureComponent,
        RetroFormComponent,
        FeelingCountComponent,
        TranslatePipe,
        ChallengeStatusTagComponent,
        ConfidenceChartComponent,
        FileValueAccessor,
        FileValidator,
        SelfEvaluationInputComponent
    ]
})
export class SharedModule { }
