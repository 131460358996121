import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AppModel } from '@app/core';
import * as _ from 'lodash';


import { IChallengeParticipants } from "@app/modules/dashboard/models/challengeParticipants";
import { IParticipant } from '@app/modules/dashboard/models/participant';
import { IPerson } from "../../models/person";

import { DashboardService } from '@app/modules/dashboard/services/dashboard.service';
import { FeedbackService } from '@app/modules/feedback/services/feedback.service';
import { LocalizationService } from "@app/core/services/localization.service";
import { NotificationService } from "@app/core/services/notification.service";


@Component({
    selector: 'feedback-to-user',
    templateUrl: './feedback-to-user.component.html',
    styleUrls : ['./feedback-to-user.component.css']
})

export class FeedbackToUserComponent implements OnInit, OnChanges {
    @Input() public challengeId: string;
    public challengeParticipants: IChallengeParticipants;
    public myParticipation: IParticipant;
    public availableParticipant: IParticipant[];
    public participant: IParticipant;

    comment: string = "";

    constructor(
        private feedbackService: FeedbackService,
        private localization: LocalizationService,
        private notification: NotificationService,
        private dashboardService: DashboardService,
        private app: AppModel,

    ) {

    }
    async ngOnInit() {
        await this.setSelectValue();
    }
   async ngOnChanges() {
       await this.setSelectValue();
    }

   async setSelectValue() {
       this.participant = null;
       this.challengeParticipants = await this.dashboardService.getParticipants(this.challengeId, this.app.language);
            this.myParticipation = this.challengeParticipants.myParticipant;
            this.availableParticipant = this.availablePersons();
    }
   availablePersons(): IParticipant[] {
      return this.challengeParticipants.participants.filter(p => p.commitment != null && p.commitment.publicId && p.commitment.description);
   }


    async  send() {
        if (this.comment.trim().length > 0) {
           if (this.participant) {
                let feedback =
                   await this.feedbackService.addFeedback(this.challengeId, this.participant.commitment.publicId, this.comment);
                feedback.canEdit = true;
                this.comment = "";
                this.participant = null;
                this.notification.showMessage(this.localization.getString('dashboard.feedback.sent'));
            } else {
                this.notification.showError(this.localization.getString('dashboard.feedback.noRecipient'), this.localization.getString('dashboard.feedbackError.action'));
            }
        } else {
            this.notification.showError(this.localization.getString('dashboard.feedbackError.message'),
                this.localization.getString('dashboard.feedbackError.action'));
        }
    }
    get placeHolder() {
        if (this.myParticipation) {
            return this.participant
                ? this.localization.getString('dashboard.feedback.placeholder') +
                " " +
                this.participant.person.firstName
                : this.localization.getString('dashboard.feedback.placeholder') + " ...";
        } else {
            return this.localization.getString('dashboard.feedback.placeholderAdmin');;
        }

    }

}
