import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFeedback } from '../../models/feedback';
import { FeedbackService } from '../../services/feedback.service';
@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['../../styles/shared.css','./feedback.component.css'],
})

export class FeedbackComponent {

    @Input() feedback: IFeedback;
    isEditing: boolean = false;
    commentBackup: string;
    @Input() challengeId: string;
   @Input() commitmentId: string;
   @Output() feedbackDeleted: EventEmitter<string> = new EventEmitter<string>();
   constructor(private feedbackService: FeedbackService) { }

    async send() {
       if (this.feedback.comment.trim().length > 0 && this.feedback.comment != this.commentBackup) {
          await this.feedbackService.updateFeedback(this.feedback.id, this.commitmentId, this.challengeId, this.feedback.comment);
          this.isEditing = !this.isEditing;
          this.commentBackup = this.feedback.comment;
       } else {
            this.feedback.comment = this.commentBackup;
        }
    }

   delete() {
      this.feedbackService.deleteFeedback(this.feedback.id, this.commitmentId, this.challengeId).then(() =>this.feedbackDeleted.emit(this.feedback.id));
   }

    setIsEditing() {
        if (this.isEditing) {
            this.send();
        }
        this.isEditing = !this.isEditing;
        this.commentBackup = this.feedback.comment;
    }

    keyPress(event: any) {
        if (event.charCode === 13) { // Enter key pressed
            this.send();
        }
    }

    toggleEdit() {
       this.isEditing = !this.isEditing;
       this.feedback.comment = this.commentBackup;
    }
}
