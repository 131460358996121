import { NgModule, Optional, SkipSelf        } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule                       } from '@angular/platform-browser';
import { BrowserAnimationsModule             } from '@angular/platform-browser/animations';
import { RouteReuseStrategy                  } from '@angular/router';
import { TransferHttpCacheModule             } from '@nguniversal/common';

import { MaterialModule         } from '@app/material';
import { MaterialServicesModule } from '@app/material';

import { AppModel } from './models/app.model';
import { ISavable } from './models/savable.model';

import { BusyDialogComponent    } from './components/busy-dialog/busy-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';

import { DefaultPageGuard } from './guards/default-page.guard';
import { LanguageGuard    } from './guards/language.guard';
import { AppModelGuard } from './guards/app-model.guard';
import { AuthHttpInterceptor } from './http/auth-http-interceptor';

import { DisableReuseStrategy } from './router/disable-reuse-strategy';

import { AppService                 } from './services/app.service';
import { BrowserService             } from './services/browser.service';
import { BusyService                } from './services/busy.service';
import { DialogService              } from './services/dialog.service';
import { FormService                } from './services/form.service';
import { IdentityService            } from './services/identity.service';
import { LocalizationService        } from './services/localization.service';
import { NavigationService          } from './services/navigation.service';
import { NotificationService        } from './services/notification.service';
import { RoutingService             } from './services/routing.service';
import { TimeService                } from './services/time.service';
import { TranslateService           } from './services/translate.service';
import { EmoticonService            } from './services/emoticon.service';
import { ApplicationInsightsService } from './services/application-insights.service';
import { SaveBarService             } from './services/save-bar.service';
import { HelpService                } from './services/help.service';
@NgModule({
    declarations: [
        BusyDialogComponent,
        MessageDialogComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        TransferHttpCacheModule,
        HttpClientModule,
        MaterialModule,
        MaterialServicesModule
    ],
    entryComponents: [
        BusyDialogComponent,
        MessageDialogComponent
    ],
    providers: [
        AppModel,
        AppService,
        BrowserService,
        BusyService,
        DialogService,
        FormService,
        IdentityService,
        LocalizationService,
        NavigationService,
        NotificationService,
        RoutingService,
        TimeService,
        TranslateService,
        DefaultPageGuard,
        AppModelGuard,
        EmoticonService,
        SaveBarService,
        LanguageGuard,
        HelpService,
        ApplicationInsightsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: DisableReuseStrategy
        }
    ]
})
export class CoreModule
{
    constructor(@Optional() @SkipSelf() loaded: CoreModule)
    {
        // Because CoreModule provides global-scoped services, we want to make sure that the module may not
        // be reloaded in lazy-loaded modules, which would create new local-scoped instances of the same services.
        if (loaded)
            throw new Error('CoreModule is already loaded and should be imported only from the root module.');
    }
}
