import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from '@app/global';
import { AppService } from './app.service';

// This class (and associated pipe) is designed to be a enhanced version of ngx-translate, which
// must be preloaded before page rendering. This can be easily done by using a global route guard.

@Injectable()
export class TranslateService {
    private items;
    private language: Language;
    private loaded: string;

    constructor(private http: HttpClient, private app: AppService) { }

    public async load(language: Language, name: string) {
        await this.loadGlobalResourcesOnLanguageMismatch(language);

        if (name && name.length > 0) {
            let key = this.getResourceNameAsKey(name);
            if (this.isResourceMissingInLoadedResources(key)) {
                await this.loadResource(name, language, key);
            }
        }
    }

    public async loadAndReplace(language: Language, name: string) {
        await this.loadGlobalResourcesOnLanguageMismatch(language);

        if (name && name.length > 0) {
            let key = this.getResourceNameAsKey(name);
            await this.loadResource(name, language, key);
        }
    }

    private getResourceNameAsKey(name: string) {
        return `;${name};`;
    }

    private isResourceMissingInLoadedResources(key: string): boolean {
        return this.loaded.indexOf(key) < 0;
    }

    private async loadResource(name: string, language: Language, key: string) {
        let values = await this.http.get(`${this.app.baseUrl}assets/i18n/${name}.${language}.json`).toPromise();
        Object.assign(this.items, values);
        this.loaded += key;
    }

    private async loadGlobalResourcesOnLanguageMismatch(language: Language) {
        if (this.hasLanguageChanged(language)) {
            this.items = await this.http.get(`${this.app.baseUrl}assets/i18n/global.${language}.json`).toPromise();
            this.loaded = '';
            this.language = language;
        }
    }

    private hasLanguageChanged(language: Language): boolean {
        return this.language !== language;
    }

    public instant(key: string, data: any = undefined): string {
        // Search for specified (sub)key
        let keys = key.split('.');
        let value = this.find(keys, this.items);
        if (!value) {
            console.error('Searching for key "' + key + '" but not found');
            return `(${key})`;
        }

        // Resolve variable tokens (if data defined)
        if (data != undefined) {
            let text: string = value;
            while (true) {
                let start = text.lastIndexOf('{{');
                if (start == -1)
                    break;

                let end = text.lastIndexOf('}}');
                if (end <= start) {
                    console.log('Invalid token syntax in message: ' + value);
                    break;
                }

                let paramKey = text.substring(start + 2, end).trim();
                let paramValue = data[paramKey];
                if (paramValue == null)
                    paramValue = `(${paramKey})`;

                text = text.substr(0, start) +
                    paramValue +
                    text.substr(end + 2);
            }
            value = text;
        }

        return value;
    }

    private find(keys: string[], data: any): string {
        if (data)
            for (let key of keys)
                if (data)
                    data = data[key];
                else
                    break;

        return data;
    }

    public exists(key: string): boolean {
        let keys = key.split('.');
        let value = this.find(keys, this.items);

        if (value)
            return true;

        return false;
    }
}
