import { Injectable             } from '@angular/core';
import { CanActivate, Router    } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot    } from '@angular/router';
import { Language               } from '@app/global';
import { RouteSnapshotTools     } from '@app/tools';
import { RoutingService         } from '../services/routing.service';
import { NavigationService      } from '../services/navigation.service';

@Injectable()
export class DefaultPageGuard implements CanActivate
{
    constructor(private router: Router,
                private routing: RoutingService,
                private navigationService: NavigationService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
    {
        let language = RouteSnapshotTools.getLanguage(route);
        if (language == null)
            language = Language.Default;

        return this.router.navigateByUrl(this.routing.getPath(this.navigationService.getDefaultPage(), language), { replaceUrl: true });
    }
}
