import { Injectable    } from '@angular/core';
import { Language      } from '@app/global';
import { IIdentityInfo } from './identity/identity-info';
import {Role} from "@app/global/role";


@Injectable()
export class AppModel
{
    public identity: IIdentityInfo;
    public language: Language;


    public isOrganizer(): boolean {
        return this.identity && this.identity.roles.includes(Role.Organizer);
    }

    public isAdmin(): boolean {
        return this.identity && this.identity.roles.includes(Role.Administrator);
    }

    public isUser(): boolean {
        return this.identity && this.identity.roles.includes(Role.User);
    }

    public isAdminOrOrganizer(): boolean {
        return this.identity && this.identity.roles.some(r => [Role.Administrator,Role.Organizer].includes(r));
    }
}
