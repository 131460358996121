import { Component           } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './busy-dialog.component.html',
    styleUrls:  ['./busy-dialog.component.css']
})
export class BusyDialogComponent
{
    public readonly message: string;

    constructor(localizationService: LocalizationService)
    {
        this.message = localizationService.getString('busy.wait');
    }
}
