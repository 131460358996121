<div *ngIf="app.identity && loaded">

    <app-tool-view [tool]="quiz">

            <quiz-lib class="teamtonic" *ngIf="!showResult"
                      [quiz]="quiz"
                      [localization]="localizationService"
                      (onQuizCompleted)="displayResults($event)">

            </quiz-lib>

            <div *ngIf="showResult" class="result">
                <div class="noprint">
                    <h2 class="results-title">{{ 'results.title' | translate }}</h2>
                    <button class="secondary" onclick='window.print()'>
                        <mat-icon>print</mat-icon>
                        <span>{{ 'results.print' | translate }}</span>
                    </button>

                    <div class="sort-container">
                        {{ 'results.printinfo' | translate }}
                        <button class="secondary" (click)="sort()">{{ 'results.sort.strength' | translate }} {{sortOrder}}</button>
                    </div>
                </div>

                <div class="result-container">
                    <div *ngFor="let r of results" class="result-card">
                        <result-lib class="teamtonic"
                                    [category]="r.category"
                                    [value]="r.value"
                                    [localization]="localizationService">

                        </result-lib>
                    </div>
                </div>

            </div>

        </app-tool-view>
    </div>
