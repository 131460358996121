import { NgModule     } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/modules/shared';
import { SendContentComponent } from './pages/send-content/send-content.component';
import { ToolBoxRoutes } from './toolbox.routes';
import { ToolBoxService } from './services/toolbox.service';
import { QuizService } from './services/quiz.service';
import { DocumentService } from './services/document.service';
import { FormsModule } from '@angular/forms';
import { QuizLibModule } from "@quiz-lib";
import { QuizComponent } from "./pages/quiz/quiz.component";
import { ToolboxViewComponent } from './pages/toolbox-view/toolbox-view.component';
import { ToolViewComponent } from './components/tool-view/tool-view.component';
import { DocumentComponent } from './pages/document/document.component';
import { ToolGroupModule } from '@app/modules/tool-group';
import { DocumentAddComponent } from './components/document-add/document-add.component';
import { ToolboxAdminComponent } from './pages/toolbox-admin/toolbox-admin.component';
import { QuizAddComponent } from './components/quiz-add/quiz-add.component';
import { ToolListComponent } from './components/tool-list/tool-list.component';
import { NavigationModule } from '@app/modules/navigation';
import { EmptyMenuComponent } from '@app/modules/navigation/components/empty-menu/empty-menu.component';
import { MainMenuComponent } from '@app/modules/navigation/components/main-menu/main-menu.component';

import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        SharedModule,
        NavigationModule,
        RouterModule.forChild(ToolBoxRoutes(SendContentComponent, QuizComponent, DocumentComponent, ToolboxViewComponent, ToolboxAdminComponent, MainMenuComponent, EmptyMenuComponent)),
        FormsModule,
        QuizLibModule,
        ToolGroupModule,
        ImageCropperModule
    ],
    declarations: [
        SendContentComponent,
        QuizComponent,
        ToolboxViewComponent,
        ToolViewComponent,
        DocumentComponent,
        DocumentAddComponent,
        ToolboxAdminComponent,
        QuizAddComponent,
        ToolListComponent

    ],
    providers: [
        ToolBoxService,
        QuizService,
        DocumentService
    ],
    entryComponents: [
        DocumentAddComponent,
        QuizAddComponent
    ]
})
export class ToolBoxModule { }
