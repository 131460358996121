<mat-toolbar class="toolbar">

    <div class="toolbar-items logo-holder">
        <img class="logo hide-phone" src="assets/img/Teamtonic.png" />
        <img class="logo display-phone" src="assets/img/Teamtonic-dark.png" />
    </div>

    <div class="toolbar-items right hide-phone">
        <div class="vl "></div>
        <a class="toolbar-items toolbar-item-text " routerLink='{{ getDefaultUrl() }}'>{{ 'main-menu.dashboard' | translate }}</a>
        <div class="vl "></div>
        <a class="toolbar-items toolbar-item-text " routerLink='{{getToolboxUrl()}}'>{{ 'main-menu.toolbox' | translate }}</a>
        <ng-container *ngIf="showAdminToolbox()">
            <div class="vl "></div>
            <a class="toolbar-items toolbar-item-text " routerLink='{{getAdminToolboxUrl()}}'>{{ 'main-menu.adminToolbox' | translate }}</a>
        </ng-container>

        <div class="vl"></div>

        <session-switch></session-switch>

        <div class="vl"></div>

        <a class="toolbar-items toolbar-item-text " routerLink='{{getFAQUrl()}}' target="_blank">
            <mat-icon>help_outlined</mat-icon>
        </a>

        <div class="vl" *ngIf="showAdminPortal()"></div>

        <div class="toolbar-items" *ngIf="showAdminPortal()">
            <a routerLink='{{ getAdminPortalUrl() }}' matTooltip="{{ 'main-menu.organisations' | translate }}">
                <mat-icon class="material-icons-outlined">account_balance</mat-icon>
            </a>
        </div>
        <div class="vl"></div>
        <language-switch></language-switch>
    </div>
</mat-toolbar>
