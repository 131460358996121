import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppModel, IdentityService, RoutingService, SaveBarService, AppService       } from '@app/core';
import { Event, Router,
         NavigationCancel, NavigationEnd,
   NavigationError, NavigationStart, ActivationEnd
} from '@angular/router';
import localeFr from '@angular/common/locales/fr-CA';
import localeEn from '@angular/common/locales/en-CA';
import { registerLocaleData, Location } from '@angular/common';
import { NavigationMobileService } from '@app/modules/navigation/services/navigation.mobile.service';

@Component({
    selector:    'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', './app.component.mobile.css']
})
export class AppComponent implements OnInit
{
    public navigating:boolean = false;
    public openedNav: boolean = false;

   constructor(private router: Router,
       public app: AppModel,
       private appService: AppService,
       private navigationService: NavigationMobileService
    )
    {
        router.events.subscribe((event: Event) =>
        {
            switch (true)
            {
                case event instanceof NavigationStart: {
                    this.navigating = true;
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.navigating = false;
                    break;
               }
            }

        });
        navigationService.toogleSideNavigation.subscribe(() => this.toggleNav());
    }

   public async ngOnInit()
    {
        registerLocaleData(localeFr, 'fr');
        registerLocaleData(localeEn, 'en');

        if (this.appService.isMobile()) {
            this.router.events.subscribe((event) => {
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
        }
    }

   public toggleNav() {
       this.openedNav = !this.openedNav;
   }

    public showNav(): boolean {
       return (this.navigationService.isBoardActive() || this.navigationService.isFeedActive() || this.navigationService.isMyContributionActive()) && this.appService.isMobile();
    }

}
