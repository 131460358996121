import { Component, Input, OnInit } from '@angular/core';
import { IFeeling } from '../../models/feeling';
import { FormControl,FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { FeelingService } from '../../services/feeling.service';
import { EmoticonService, TranslateService } from '@app/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppModel } from '@app/core';

@Component({
    selector: 'self-evaluation-input',
    templateUrl: './self-evaluation-input.component.html',
    styleUrls: ['../../../shared/icons.shared.css','./self-evaluation-input.component.css','./self-evaluation-input.component.mobile.css'],

})

export class SelfEvaluationInputComponent implements OnInit{

    public feelings: IFeeling[];
    @Input() selfEvaluationFormGroup: FormGroup;
   public loaded:boolean = false;
    constructor(private app: AppModel,
       private feelingService: FeelingService,
       private emoticonService: EmoticonService,
       private translateService: TranslateService) {
      
      
   }

    async ngOnInit() {
        await this.translateService.load(this.app.language, "retro-form");
        this.feelings = await this.feelingService.getFeelings();
      
        this.loaded = true;

   }


    getEmojiUrl(emoji: string) {
        return this.emoticonService.emojisUrl.get(emoji);
    }

    get selectedFeeling() :IFeeling{
 
        return this.feelings.find(f => f.id === this.feeling.value);
    }

    get feeling(){
        return this.selfEvaluationFormGroup.get("feeling");
    }

    get evaluation(){
        return this.selfEvaluationFormGroup.get("evaluation");
    }

}
