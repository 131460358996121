import { Injectable             } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService    } from './localization.service';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { IMessageDialogData     } from '../components/message-dialog/message-dialog-data';

@Injectable()
export class DialogService
{
    constructor(private dialog:       MatDialog,
                private localization: LocalizationService) { }

    public show(message: string, title?: string): Promise<boolean | undefined>
    {
        return this.open(
        {
            message,
            title,
            accept: this.localization.getString('global.ok')
        });
    }

    public confirm(message: string, title?: string): Promise<boolean | undefined>
    {
        return this.open(
        {
            message,
            title,
            accept: this.localization.getString('global.ok'),
            reject: this.localization.getString('global.cancel')
        });
    }

    public discardChange(title?: string): Promise<boolean | undefined> {
        return this.open(
            {
                message: this.localization.getString('global.loseChange'),
                title,
                accept: this.localization.getString('global.continue'),
                reject: this.localization.getString('global.cancel'),
            });
    }

    public query(message: string, title?: string): Promise<boolean | undefined>
    {
        return this.open(
        {
            message,
            title,
            accept: this.localization.getString('global.yes'),
            reject: this.localization.getString('global.no')
        });
    }

    private open(data: IMessageDialogData): Promise<boolean | undefined>
    {
        return this.dialog.open(MessageDialogComponent, { data }).afterClosed().toPromise();
    }

    
}
